import axios from 'axios';
import { ICustomerForAgentResponse } from '../../interfaces/ICustomerForAgent';

const { REACT_APP_AGENT_GET_CUSTOMERS_URL } = process.env;

const getCustomers = async (token: string, filters?: Record<string, any>) => {
  if (!REACT_APP_AGENT_GET_CUSTOMERS_URL) {
    throw new Error('API URL is not defined in environment variables.');
  }

  try {
    const response = await axios.get<ICustomerForAgentResponse>(REACT_APP_AGENT_GET_CUSTOMERS_URL, {
      params: filters,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error.response?.data || error.message;
    } else {
      throw error;
    }
  }
};

export default getCustomers;
