import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import queryString from 'query-string';
import { AlertType, useAlert } from '../../providers/AlertProvider';
import { PrimaryButtonFull } from '../../components/Buttons';
import InputButtonPresentational from '../../components/Form/InputButtonPresentational';
import Subtotal from '../../components/Subtotal';
import ShoppingCartTable from '../../components/ProductsTable/ShoppingCartTable';
import { CartStatus, ICart } from '../../lib/fetch/carts';
import axios from 'axios';
import { useAuth } from '../../providers/AuthProvider';
import { calculateCartTotals } from '../../lib/utils/cart';
import { useCart } from '../../providers/CartProvider';
import GiftProductsListBoxes from './GiftProductsListBoxes';
import { OutlineButton } from '../../components/Buttons/OutlineButton';
import { Bars4Icon } from '@heroicons/react/24/solid';
import { useMutation } from 'react-query';
import { IQuoteAttributes } from '../../lib/fetch/quotes';
import createQuote from '../../lib/fetch/quotes/create';
import moment from 'moment';
import { QuoteStatus } from '../../lib/enums';
import InfoAlert from '../../components/Alert/InfoAlert';
import { IPromoApplication } from '../../lib/interfaces/IPromoApplication';
import { IPromotion } from '../../lib/fetch/products';
const { REACT_APP_RVA_APPROVE_CART_URL, REACT_APP_RVA_DISAPPROVE_CART_URL } = process.env;

interface IShoppingCartPresentational {
  cart: ICart;
  deliveryAddressId?: number;
  readOnly?: boolean;
  onProductSelection: ({ productId, promoApplicationId }: { productId: string; promoApplicationId: string }) => void;
  onProductDeselection: ({ promoApplicationId }: { promoApplicationId: string }) => void;
}

const ShoppingCartPresentational: FC<IShoppingCartPresentational> = (props) => {
  const { deliveryAddressId, readOnly = true, cart, onProductDeselection, onProductSelection } = props;
  const {
    onSearchAddToCart,
    setIsOverlayEnabled,
    cartState: { isLoading },
  } = useCart();

  const [statufullCart, setStatefullCart] = useState<ICart | undefined>(cart || undefined);
  const [{ token, user }] = useAuth();
  let { cart_items: cartItems, shipping: cartShipping } = cart || {};
  cartItems = cartItems || [];
  cartShipping = cartShipping || 0;
  const [, dispatchAlertChange] = useAlert();
  const { t } = useTranslation('SHOPPING_CART');
  const { fido } = user || {};
  const selectedForOrderCartItems = cartItems.filter((cartItem) => cartItem.selectedForOrder);
  const navigate = useNavigate();

  const [giftProductsList, setGiftProductsList] = useState<
    {
      promoApplication?: Pick<IPromoApplication, 'selectedGift' | 'tier'>;
      promotion: IPromotion;
      selectedForOrder: boolean;
    }[]
  >([]);

  const approveCart = async () => {
    try {
      const response = await axios.post(
        REACT_APP_RVA_APPROVE_CART_URL || '',
        {
          cartId: cart?.id,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
      setStatefullCart(response.data);
    } catch (error) {
      dispatchAlertChange({
        open: true,
        type: AlertType.Error,
        message: t('CART_APPROVAL_ERROR'),
      });
    }
  };

  const rejectCart = async () => {
    try {
      const response = await axios.post(
        REACT_APP_RVA_DISAPPROVE_CART_URL || '',
        {
          cartId: cart?.id,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
      setStatefullCart(response.data);
    } catch (error) {
      dispatchAlertChange({
        open: true,
        type: AlertType.Error,
        message: t('CART_REJECTION_ERROR'),
      });
    }
  };
  //Disable the overlay for this page only, when the component dismounts it will be enabled again
  useEffect(() => {
    setTimeout(() => setIsOverlayEnabled(false), 0);

    return () => {
      setTimeout(() => setIsOverlayEnabled(true), 0);
    };
  }, [setIsOverlayEnabled]);

  const cartItemsForOrder = cartItems.filter((item) => item.selectedForOrder);
  const cartItemForLater = cartItems.filter((item) => !item.selectedForOrder);

  const createQuoteMutation = useMutation((data: IQuoteAttributes) => createQuote(token!, data), {
    onSuccess: ({ data }) => {
      dispatchAlertChange({ type: AlertType.Success, open: true, message: t('QUOTE:QUOTE_CREATED_SUCCESSFULLY') });
      navigate(`/products-list/${data.id}`);
    },
    onError: () => {
      dispatchAlertChange({ type: AlertType.Error, open: true, message: t('QUOTE:ERROR_DURING_UPDATE_QUOTE') });
    },
  });

  const handleCreateProductList = () => {
    createQuoteMutation.mutate({
      name: moment().format('YYYY-MM-DD'),
      description: '',
      //TODO: refactor interface once custom endpoint is created, on the other side is handled with a cast
      //@ts-ignore
      items: cartItemsForOrder.map((item) => ({
        quantity: item.quantity,
        product: {
          id: item.product.id,
        },
      })),
      status: QuoteStatus.Pending,
    });
  };

  return (
    <div>
      <div className="py-10 px-4">
        <h2 className="mb-6 text-2xl font-bold text-gray-900">{t('TITLE')}</h2>

        <div className="flex flex-col gap-6 lg:flex-row">
          {readOnly ? (
            <div className="flex h-full min-w-0 flex-1 flex-col">
              <div className="mt-6 border-t py-6">
                <ShoppingCartTable cartItems={cartItems} readOnly={readOnly} />
              </div>
            </div>
          ) : (
            <div className="flex h-full min-w-0 flex-1 flex-col">
              <InputButtonPresentational
                onSubmit={(search) => onSearchAddToCart(search)}
                placeholder={t('PLACEHOLDER_SEARCH')}
                textButton={t('MENU:ADD_TO_CART')}
                type="text"
                loading={isLoading}
              />
              {giftProductsList.length > 0 && (
                <div className="mt-6">
                  <p className="text-lg font-bold text-gray-900">{t('GIFT_PRODUCTS_INFO')}</p>
                  <p className="text-lg font-bold text-gray-900">{t('GIFT_PRODUCTS_INFO_SELECT')}</p>
                </div>
              )}
              <GiftProductsListBoxes
                onProductDeselection={onProductDeselection}
                onProductSelection={onProductSelection}
                setGiftProductsList={setGiftProductsList}
              />
              <div className="mt-6 border-t py-6">
                <h2 className="mb-6 text-2xl font-bold text-gray-900">{t('PRODUCT_CART')}</h2>
                <ShoppingCartTable cartItems={cartItemsForOrder} loading={isLoading} />
              </div>
              {cartItemForLater.length > 0 && (
                <div className="mt-6 border-t py-6">
                  <h2 className="mb-6 text-2xl font-bold text-gray-900">{t('PRODUCT_SAVED')}</h2>
                  <ShoppingCartTable cartItems={cartItemForLater} loading={isLoading} />
                </div>
              )}
            </div>
          )}

          <aside className="mx-auto w-full overflow-y-auto bg-gray-50 py-6 lg:w-1/5 shadow-md rounded-xl h-fit">
            <div className="px-4">
              {/* TODO: delivery estimate */}
              {/*<div className="rounded-md bg-yellow-50 px-2 py-3 text-center shadow-sm w-full border border-yellow-700 mb-6">*/}
              {/*  <p className="text-sm text-yellow-700">*/}
              {/*    {t('ESTIMATE_DELIVERY_FOR')}{' '}*/}
              {/*    <span className="uppercase">domani</span>*/}
              {/*  </p>*/}
              {/*</div>*/}
              <h3 className="mb-4 text-lg font-bold text-gray-900">{t('ORDER_SUMMARY')}</h3>
            </div>
            <Subtotal
              cartItems={selectedForOrderCartItems}
              shipping={cartShipping}
              shippingAddress={cart?.shipping_address}
            />
            <div className="mt-10 px-4">
              {readOnly ? (
                statufullCart?.status === CartStatus.APPROVED ? (
                  <div className="flex">
                    <button className="w-full rounded-md bg-green-500 py-3 mx-4 font-bold text-white" disabled>
                      Approvato
                    </button>
                  </div>
                ) : statufullCart?.status === CartStatus.REJECTED ? (
                  <div className="flex">
                    <button className="w-full rounded-md bg-orange-400 py-3 mx-4 font-bold" disabled>
                      Rifiutato
                    </button>
                  </div>
                ) : (
                  <div className="flex">
                    <button className="w-full rounded-md bg-orange-400 py-3 mx-4 font-bold" onClick={rejectCart}>
                      Rifiuta Carrello
                    </button>
                    <button
                      className="w-full rounded-md bg-green-500 py-3 mx-4 font-bold text-white"
                      onClick={approveCart}
                    >
                      Approva Carrello
                    </button>
                  </div>
                )
              ) : (
                <>
                  <div className="pb-8 justify-center text-center ">
                    <InfoAlert text={t('ORDER_PROCESSING_INFO')} />
                  </div>
                  <div className="pb-2">
                    <OutlineButton
                      icon={<Bars4Icon className="w-4 h-4" />}
                      text={t('QUOTE:CREATE_PRODUCT_LIST')}
                      onClick={() => {
                        handleCreateProductList();
                      }}
                      disabled={cartItemsForOrder.length === 0}
                    />
                  </div>
                  <Link
                    to={
                      '/checkout-shipping' +
                      (deliveryAddressId ? `?${queryString.stringify({ deliveryAddressId })}` : '')
                    }
                    onClick={(e) => {
                      const { total } = calculateCartTotals(selectedForOrderCartItems || [], cartShipping);
                      if (fido?.max !== 0 && total > (fido?.remaining || 0)) {
                        e.preventDefault();
                        dispatchAlertChange({
                          open: true,
                          message: t('INSUFFICIENT_FIDO'),
                        });
                        return;
                      }
                      if (selectedForOrderCartItems?.length && total > 0) return;
                      e.preventDefault();
                      dispatchAlertChange({
                        open: true,
                        message: t('EMPTY_CART_ERROR'),
                      });
                    }}
                  >
                    <PrimaryButtonFull
                      text={t('CHECKOUT_BTN')}
                      onClick={(e) => {
                        const isCartNotApprovedForAgent =
                          cart?.status !== CartStatus.APPROVED && user?.role?.type === 'agente';

                        if (user?.customer.status === 3 || !user!.ordersEnabled || isCartNotApprovedForAgent) {
                          e.preventDefault();
                          dispatchAlertChange({
                            open: true,
                            message: isCartNotApprovedForAgent ? t('CART_NOT_APPROVED') : t('CHECKOUT_BTN_DISABLED'),
                          });
                          return;
                        }
                      }}
                    />
                  </Link>
                </>
              )}
            </div>
          </aside>
        </div>
      </div>
    </div>
  );
};

export default ShoppingCartPresentational;
