import axios from 'axios';
import { IPromotionTier } from '.';

const { REACT_APP_BACKEND_URL } = process.env;

interface IItem {
  productId: number;
  quantity: number;
}

export interface IGetProductsFinalPricesResponse {
  productId: number;
  price_net: number;
  price_list: number;
  promo_price: number;
  bestPromotionTier: IPromotionTier;
}
const getProductsFinalPrices = async (params: {
  token: string;
  items: IItem[];
}): Promise<IGetProductsFinalPricesResponse[]> => {
  const { token, items } = params;
  try {
    const response = await axios.post(
      `${REACT_APP_BACKEND_URL}/products/getProductsFinalPrice`,
      {
        items,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export default getProductsFinalPrices;
