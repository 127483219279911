import { UseMutateAsyncFunction } from 'react-query';
import { ICart } from '../../fetch/carts';
import { IProduct } from '../../fetch/products';
import { ICartItem, ICartItemUpdateResult } from '../../fetch/cartItems';
import { IProductQuantity } from '../../../components/ProductsTable';

export interface CartState {
  cart: ICart | null;
  isLoading: boolean;
  totalQuantity: number;
  isFlushingCart: boolean;
  setCart: (cart: ICart) => void;
  itemLoading: { [key: number]: boolean };
  itemAdding: { [key: number]: boolean };
  itemRemoving: { [key: number]: boolean };
  itemUpdating: { [key: number]: boolean };
  isAddingBulk: boolean;
}

export enum CartActionType {
  SetCart = 'SET_CART',
  EmptyCart = 'EMPTY_CART',
  SetLoading = 'SET_LOADING',
  SetItemLoading = 'SET_ITEM_LOADING',
  SetItemAdding = 'SET_ITEM_ADDING',
  SetItemRemoving = 'SET_ITEM_REMOVING',
  SetItemUpdating = 'SET_ITEM_UPDATING',
  SetFlushingCart = 'SET_FLUSHING_CART',
  SetAddingBulk = 'SET_ADDING_BULK',
}

export interface CartAction {
  type: CartActionType;
  cart?: ICart | null;
  isLoading?: boolean;
  itemId?: number;
  isItemLoading?: boolean;
  isItemAdding?: boolean;
  isFlushingCart?: boolean;
  isItemRemoving?: boolean;
  isItemUpdating?: boolean;
  isAddingBulk?: boolean;
}

export interface AddToCartParams {
  product: IProduct;
  quantity: number;
  displayAddToCartModal?: boolean;
}

export interface UpdateCartItemParams {
  cartItem: ICartItem;
  newQuantity: number;
}

export interface SelectAllForOrderParams {
  token: string;
  cartId: number;
  selectedForOrder: boolean;
}

export interface ICartContext {
  cartState: CartState;
  dispatchState: (_action: CartAction) => void;
  addToCart: (params: AddToCartParams) => Promise<void>;
  fetchCart: UseMutateAsyncFunction<any, unknown, void, unknown>;
  onAddAll: UseMutateAsyncFunction<
    any,
    unknown,
    { products: IProduct[]; productQuantities: IProductQuantity[] },
    unknown
  >;
  onFlushCart: () => void;
  onItemRemove: (cartItem: ICartItem) => Promise<void>;
  onItemQuantityChange: (params: UpdateCartItemParams) => Promise<void>;
  dispatchOverlayChange: (params: { open: boolean }) => void;
  isOverlayOpen: boolean;
  onSearchAddToCart: (search: string) => Promise<void>;
  onExcerptChange: (cartItem: ICartItem, excerpt: boolean) => Promise<void>;
  onSelectedForOderChange: (cartItem: ICartItem, selected: boolean) => Promise<void>;
  flushCartWithConfirm: () => Promise<void>;
  selectAllForOrder: (params: SelectAllForOrderParams) => Promise<{ data: { data: ICartItemUpdateResult } }>;
  isOverlayEnabled: boolean;
  setIsOverlayEnabled: (isOverlayEnabled: boolean) => void;
  adding: boolean;
}
