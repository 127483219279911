import { Disclosure } from '@headlessui/react';
import { ChevronRightIcon } from '@heroicons/react/24/outline';
import { Link } from 'react-router-dom';
import EditField from './EditField';
// import ExtendedPriceField from './ExtendedPriceField';
import { getThumbnail } from '../index';
import { HIDDEN_MANUFACTURERS_NAMES } from '../../../lib/constants';
import { ICartItem } from '../../../lib/fetch/cartItems';
import { getTecdocManufacturerById, getTecdocManufacturerByName } from '../../../lib/utils/manufacturers';
import DetailsDisclousre from '../DetailsDisclosure';
import ExcerptField from './ExcerptField';
import { useAuth } from '../../../providers/AuthProvider';
import { CheckBoxCustom } from '../../Checkbox';
import { ITecdocManufacturer } from '../../../lib/fetch/manufacturers';
import { useState } from 'react';
import { getProductOrderedQuantities } from '../utils';
import findProductStock from '../../../lib/fetch/products/findProductStock';
import { useQuery } from 'react-query';
import { useCart } from '../../../providers/CartProvider';
import { ROUTES } from '../../../routes';
import { currency } from '../../../lib/utils';
import { MadeOrderBadge, OutProductionBadge, PiecesBadge, UnavailableBadge, WarehouseModal } from '../../StockBadge';
import { getCrossReferences, IProduct } from '../../../lib/fetch/products';
import i18n from '../../../i18n';
import Spinner from '../../Spinner';
import PriceDisplay from '../PriceDisplay/PriceDisplay';
import { getDiscountedPricePercentage } from '../../../lib/utils/promotions';

type Props = {
  cartItem: ICartItem;
  selectedItems: number[];
  handleItemChange: (item: any, isChecked: boolean) => void;
  tecdocManufacturers: ITecdocManufacturer[];
  readOnly: boolean;
  isLoading?: boolean;
};

const ShoppingCartRow: React.FunctionComponent<Props> = (props) => {
  const [{ user, token }] = useAuth();
  const { onExcerptChange } = useCart();
  const { cartItem, selectedItems, handleItemChange, tecdocManufacturers, readOnly, isLoading } = props;
  const [requestedQuantity, setRequestedQuantity] = useState<number>(cartItem.quantity);
  const [canUseExcerpt, setCanUseExcerpt] = useState<boolean>(false);
  const lang = i18n.languages[0];

  useQuery(['findProductStock', requestedQuantity], () => findProductStock({ id: cartItem.product.id, token }), {
    onSuccess: (productStock) => {
      const { showRedProductsOption } = getProductOrderedQuantities({
        productStock,
        user,
        requestedQuantity,
        cartCurrentQuantity: cartItem.quantity,
      });
      setCanUseExcerpt(cartItem.price !== 0 && user!.customer?.excerpt && showRedProductsOption);
    },
  });

  const onFetchCrossReferences = async (productId: number): Promise<IProduct[]> => {
    const { error, data: responseData } = await getCrossReferences(token!, productId, lang);
    if (error || !responseData.data) {
      return [];
    }
    return responseData.data;
  };

  return (
    <Disclosure>
      {({ open }) => (
        <>
          <tr className="border-t">
            <td className="relative py-3 pl-2">
              {cartItem.price !== 0 && !isLoading && (
                <CheckBoxCustom
                  item={cartItem}
                  onChange={handleItemChange}
                  isChecked={selectedItems.includes(cartItem.id)}
                  readOnly={cartItem.price === 0}
                />
              )}
              {isLoading && <Spinner style={{ color: 'red' }} />}
            </td>
            <td className="py-2 pl-2">
              <Disclosure.Button className="bg-white text-gray-600 focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75">
                <ChevronRightIcon className={`${open ? 'rotate-90 transform' : ''} h-4 w-4`} />
              </Disclosure.Button>
            </td>
            <td className="whitespace-nowrap py-2 pl-2 pr-4">
              <div className="flex items-center">
                {getThumbnail(cartItem.product.tecdoc?.images[0]?.imageURL3200 || cartItem.product.thumbnail)}
                <Link to={`${ROUTES.SEARCH.PRODUCT_DETAIL}/${cartItem.product.id}`} className="ml-3">
                  <div className="text-sm font-medium text-gray-900">{cartItem.product.code}</div>
                  <div className="text-sm font-normal text-gray-400">
                    {cartItem.product.description ||
                      cartItem.product.tecdoc?.genericArticles[cartItem.product.tecdoc?.genericArticles.length - 1]
                        ?.genericArticleDescription ||
                      cartItem.product.family?.name}
                  </div>
                </Link>
              </div>
            </td>
            <td className="whitespace-nowrap px-4 py-2">
              {cartItem.product.tecdoc?.dataSupplierId &&
              getTecdocManufacturerById(cartItem.product.tecdoc?.dataSupplierId, tecdocManufacturers) ? (
                <img
                  className="h-auto w-20"
                  src={
                    getTecdocManufacturerById(cartItem.product.tecdoc?.dataSupplierId, tecdocManufacturers)!
                      .dataSupplierLogo.imageURL800
                  }
                  alt=""
                />
              ) : cartItem.product.manufacturer?.name &&
                getTecdocManufacturerByName(cartItem.product.manufacturer.name, tecdocManufacturers) ? (
                <img
                  className="h-auto w-20"
                  src={
                    getTecdocManufacturerByName(cartItem.product.manufacturer.name, tecdocManufacturers)!
                      .dataSupplierLogo.imageURL800
                  }
                  alt=""
                />
              ) : cartItem.product.manufacturer?.name &&
                !HIDDEN_MANUFACTURERS_NAMES.includes(`${cartItem.product.manufacturer?.name}`.toUpperCase()) ? (
                cartItem.product.manufacturer.name
              ) : (
                cartItem.product.manufacturer?.id || '-'
              )}
            </td>
            <td className="whitespace-nowrap px-4 py-2 text-sm text-gray-500">
              {!cartItem.product.attributes?.active && <OutProductionBadge />}
              {cartItem.product.attributes?.active &&
                cartItem.product.attributes.quantity.quantityDefaultWarehouse === 0 &&
                cartItem.product.attributes.quantity.quantityOtherWarehouses === 0 && <UnavailableBadge />}
              {cartItem.product.attributes?.active &&
                cartItem.product.attributes.quantity.quantityDefaultWarehouse > 0 && (
                  <PiecesBadge quantity={cartItem.product.attributes.quantity.quantityDefaultWarehouse} />
                )}
              {cartItem.product.attributes?.active &&
                cartItem.product.attributes.quantity.quantityDefaultWarehouse === 0 &&
                cartItem.product.attributes.quantity.quantityOtherWarehouses > 0 && (
                  <>
                    <MadeOrderBadge quantity={cartItem.product.attributes.quantity.quantityOtherWarehouses} />
                    <WarehouseModal otherWarehouses={cartItem.product.attributes.quantity.otherWarehouses} />
                  </>
                )}
            </td>
            {/*             <td className="whitespace-nowrap px-4 py-2 text-sm font-semibold">
              <ExtendedPriceField cartItem={cartItem} />
            </td> */}
            <td className="whitespace-nowrap px-4 py-2 text-sm font-semibold text-gray-700">
              {cartItem.product.price_list ? <p>{currency(cartItem.product.price_list)}</p> : '-'}
            </td>
            <td className="whitespace-nowrap px-4 py-2 text-sm font-semibold text-gray-700">
              {cartItem.price ? <p>{currency(cartItem.price)}</p> : '-'}
            </td>
            <td className="whitespace-nowrap px-4 py-2 text-sm font-semibold text-gray-700">
              {cartItem.price ? (
                <>
                  <PriceDisplay
                    basePrice={cartItem.original_price * cartItem.quantity}
                    finalPrice={cartItem.price * cartItem.quantity}
                    isPromo={cartItem.original_price !== cartItem.price}
                    isLoading={false}
                    discountPercentage={getDiscountedPricePercentage(cartItem.price, cartItem.original_price)}
                  />
                </>
              ) : (
                '-'
              )}
            </td>

            <td className="whitespace-nowrap px-4 py-2 text-sm font-semibold">
              {canUseExcerpt && <ExcerptField cartItem={cartItem} onExcerptChange={onExcerptChange} />}
            </td>
            <td className="sticky right-0 z-5 bg-white whitespace-nowrap px-4 py-2 text-right text-sm font-medium">
              <EditField cartItem={cartItem} readOnly={readOnly} setRequestedQuantity={setRequestedQuantity} />
            </td>
          </tr>
          <tr className="border-0">
            <td colSpan={5} className="border-0">
              <DetailsDisclousre
                product={cartItem.product}
                promotion={cartItem.promotion}
                onFetchCrossReferences={onFetchCrossReferences}
                showTechnicalSpecs={true}
                showCrossReferences={true}
                showPromotions={true}
              />
            </td>
          </tr>
        </>
      )}
    </Disclosure>
  );
};

export default ShoppingCartRow;
