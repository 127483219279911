import { Disclosure } from '@headlessui/react';
import { ChevronUpIcon } from '@heroicons/react/20/solid';
import { useEffect, useMemo, useState } from 'react';
import { IPromoApplication } from '../../../lib/interfaces/IPromoApplication';
import { IPromotion } from '../../../lib/fetch/products';
import { BookmarkIcon } from '@heroicons/react/24/outline';
import ChoiceProduct from './ChoiceProduct';
import { OutlineButton } from '../../Buttons/OutlineButton';
import { getMediaUrl } from '../../../lib/utils';
import { useTranslation } from 'react-i18next';

interface Props {
  promoApplication: Pick<IPromoApplication, 'selectedGift' | 'tier'> & { id: number };
  promotion: IPromotion;
  onProductSelection: ({ productId, promoApplicationId }: { productId: string; promoApplicationId: string }) => void;
  onProductDeselection: ({ promoApplicationId }: { promoApplicationId: string }) => void;
}

const ChoiceProductBox: React.FunctionComponent<Props> = (props) => {
  const { t } = useTranslation('SHOPPING_CART');

  const { promoApplication, promotion, onProductSelection, onProductDeselection } = props;
  const promotionProducts = useMemo(() => promoApplication.tier.effect.giftProducts, [promotion]);
  const giftQuantity = useMemo(() => promoApplication.tier.effect.giftQuantity, [promotion]);
  const selectedProduct = useMemo(() => promoApplication.selectedGift?.product, [promoApplication]);
  const [selectedProductId, setSelectedProductId] = useState<number | undefined>(selectedProduct?.id);

  useEffect(() => {
    setSelectedProductId(selectedProduct?.id);
  }, [selectedProduct]);

  return (
    <div className="w-full mx-auto overflow-hidden border-gray-200 border rounded-lg">
      <Disclosure>
        {({ open }) => (
          <>
            <Disclosure.Button className="flex w-full items-center justify-between rounded-lg bg-gray-50 px-4 py-3 text-sm font-medium hover:bg-gray-100 focus:outline-none focus-visible:ring">
              <div className="flex items-center gap-4">
                <ChevronUpIcon className={`${open ? 'rotate-180 transform' : ''} h-5 w-5 text-gray-500`} />
                <div>
                  <p className="font-semibold text-[#379FE0] text-left">{promotion.title}</p>
                  {selectedProduct ? (
                    <div>
                      <p className="text-gray-400 text-xs font-light">{t('CHOOSEN_PRODUCT')}:</p>
                      <div className="flex flex-row items-center">
                        <img
                          src={getMediaUrl(selectedProduct.thumbnail)}
                          alt={selectedProduct.code}
                          className="h-8 w-8 object-cover"
                        />
                        <strong className="text-gray-600 text-xs font-semibold">{selectedProduct.code}</strong>
                      </div>
                    </div>
                  ) : (
                    <p className="text-gray-400 text-xs font-light">{t('SELECT_GIFT_PRODUCT')}</p>
                  )}
                </div>
              </div>
              <div className="flex gap-2 flex-col">
                <OutlineButton
                  text={t('ADD_GIFT_PRODUCT')}
                  onClick={() =>
                    onProductSelection({
                      promoApplicationId: String(promoApplication.id),
                      productId: String(selectedProductId),
                    })
                  }
                  //il pulsante rimane disabilitato se il prodotto selezionato è già quello omaggio scelto
                  disabled={
                    (Boolean(selectedProduct) && String(selectedProduct?.id) === String(selectedProductId)) ||
                    !selectedProductId
                  }
                  icon={<BookmarkIcon className="h-4 w-4" />}
                />
                {selectedProduct && (
                  <button
                    onClick={(e) => {
                      onProductDeselection({
                        promoApplicationId: String(promoApplication.id),
                      });
                      e.preventDefault();
                    }}
                    className="px-4 py-2 text-gray-500 text-xs underline rounded-md hover:text-gray-600"
                  >
                    {t('REMOVE_GIFT_PRODUCT')}
                  </button>
                )}
              </div>
            </Disclosure.Button>
            <Disclosure.Panel className="">
              {promotionProducts?.map((product: any, idx) => (
                <ChoiceProduct
                  product={product}
                  key={idx}
                  giftQuantity={Number(giftQuantity)}
                  selectedProductId={Number(selectedProductId)}
                  onSelectProduct={(_productId) => {
                    setSelectedProductId(_productId);
                  }}
                />
              ))}
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </div>
  );
};

export default ChoiceProductBox;
