import { ConditionType, IProduct, IPromotion } from '../fetch/products';
import { IUser } from '../interfaces';

const MAX_PRODUCT_QUANTITY = Infinity;

const quantityInputProps = (product: IProduct, user?: IUser) => {
  const { attributes } = product;
  const { active, sales_package_sizes, price_net, price_list } = attributes;
  const { customer = null } = user || {};

  const canBuyOutOfStockUser = user?.canBuyOutOfStock ?? false;
  const canBuyOutOfStockCustomer = customer?.canBuyOutOfStock ?? false;

  const quantity =
    attributes.quantity ||
    ('attributes' in attributes ? (attributes as unknown as IProduct).attributes.quantity : undefined);

  const isUnavailable = !active;

  const allWarehousesQuantity = (quantity?.quantityDefaultWarehouse || 0) + (quantity?.quantityOtherWarehouses || 0);

  const isStockAvailable = allWarehousesQuantity > 0;

  const canBuyOutOfStock = canBuyOutOfStockUser && canBuyOutOfStockCustomer;

  const canBuy = !isUnavailable && (isStockAvailable || canBuyOutOfStock) && (price_net ?? price_list ?? 0) > 0;
  const package_size = sales_package_sizes || 1;
  const promotion_size = package_size;

  const maxValue = canBuy ? (canBuyOutOfStock ? MAX_PRODUCT_QUANTITY : allWarehousesQuantity) : 0;

  const min = Math.min(package_size, allWarehousesQuantity);
  const defaultValue = Math.min(promotion_size, allWarehousesQuantity);
  return {
    max: canBuy && maxValue > 0 ? maxValue : 1,
    min: canBuy && min > 0 ? min : 1,
    defaultValue: canBuy && defaultValue > 0 ? defaultValue : 1,
    disabled: !canBuy,
    step: package_size,
  };
};

const getPromotionMin = (promotion: IPromotion) => {
  const min = promotion.mainPromoTiers.reduce((min, tier) => {
    if (tier.condition.type === ConditionType.NUMERO_PEZZI) {
      return Math.min(tier.condition.quantity!, min);
    }
    if (tier.condition.type === ConditionType.VOLUME_SPESA) {
      return Math.min(tier.condition.priceVolume!, min);
    }
    return min;
  }, Infinity);

  return min === Infinity ? 1 : min;
};

export default quantityInputProps;
