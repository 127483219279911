import { useEffect, useMemo } from 'react';
import { ChoiceProductBox } from '../../components/Cart';
import { useCart } from '../../providers/CartProvider';
import { IPromoApplication } from '../../lib/interfaces/IPromoApplication';
import { IPromotion } from '../../lib/fetch/products';

interface Props {
  onProductSelection: ({ productId, promoApplicationId }: { productId: string; promoApplicationId: string }) => void;
  onProductDeselection: ({ promoApplicationId }: { promoApplicationId: string }) => void;
  setGiftProductsList: (
    giftProductsList: {
      promoApplication?: Pick<IPromoApplication, 'selectedGift' | 'tier'>;
      promotion: IPromotion;
      selectedForOrder: boolean;
    }[],
  ) => void;
}

const GiftProductsListBoxes: React.FunctionComponent<Props> = (props) => {
  const { onProductDeselection, onProductSelection, setGiftProductsList } = props;
  const { cartState } = useCart();
  const giftProductsList = useMemo(
    () =>
      cartState.cart?.cart_items
        .map((item) => ({
          promoApplication: item.promoApplication,
          promotion: item.promotion,
          selectedForOrder: item.selectedForOrder,
        }))
        .filter(
          (_elem) =>
            _elem.promoApplication?.tier.effect.giftProducts.length &&
            _elem.promoApplication?.tier.effect.giftProducts.length > 1 &&
            _elem.selectedForOrder,
        ) || [],
    [cartState],
  );

  useEffect(() => {
    setGiftProductsList(giftProductsList);
  }, [giftProductsList]);

  return (
    <div className="py-6 space-y-4">
      {giftProductsList.map(
        (_promoApplication, idx) =>
          _promoApplication.promoApplication && (
            <ChoiceProductBox
              promoApplication={_promoApplication.promoApplication}
              promotion={_promoApplication.promotion}
              key={idx}
              onProductDeselection={onProductDeselection}
              onProductSelection={onProductSelection}
            />
          ),
      )}
    </div>
  );
};

export default GiftProductsListBoxes;
