import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IProduct, IPromotion } from '../../lib/fetch/products';
import { NO_IMAGE_PLACEHOLDER_CATI_RED } from '../../lib/constants';
import { ImageMediaType } from '../../lib/enums';
import { SingleProduct } from './SingleProduct';
import { useQuery } from 'react-query';
import { useAuth } from '../../providers/AuthProvider';
import getProductsFinalPrices from '../../lib/fetch/products/getProductsFinalPrices';

export { default as HorizontalFilter } from './HorizontalFilter';
export { default as Pagination } from './Pagination';
export { default as WarehouseTable } from './WarehouseTable';

interface IProductsTable {
  onFetchCrossReferences?: (productId: number) => Promise<IProduct[]>;
  products: IProduct[];
  onQuantitiesChange?: (productQuantities: IProductQuantity[]) => void;
}

export interface IProductQuantity {
  id: number;
  quantity: number;
  assortedQuantity?: number;
  touched?: boolean;
}

export const isBackgroundCompatible = (thumbnail: string) => {
  const url = (thumbnail || '').split('?').shift();
  const ext = (url || '').split('.').pop();
  return ([ImageMediaType.JPG, ImageMediaType.JPEG, ImageMediaType.PNG] as string[]).includes(
    (ext || '').toUpperCase(),
  );
};
export const getThumbnail = (thumbnail?: string) => {
  if (!thumbnail)
    return (
      <div
        className="h-12 w-12 border border-gray-200 bg-cover bg-center bg-no-repeat"
        style={{
          backgroundImage: `url(${NO_IMAGE_PLACEHOLDER_CATI_RED})`,
          minWidth: '3rem',
        }}
      />
    );
  if (isBackgroundCompatible(thumbnail))
    return (
      <div
        className="h-12 w-12 border border-gray-200 bg-cover bg-center bg-no-repeat"
        style={{
          backgroundImage: `url(${encodeURI(thumbnail)})`,
          minWidth: '3rem',
        }}
      />
    );
  return <img className="h-12 w-12" style={{ minWidth: '3rem' }} src={encodeURI(thumbnail)} alt="thumbnail" />;
};
const initializeQuantities = (products: IProduct[]): IProductQuantity[] =>
  products.map((product) => ({
    id: product.id,
    quantity: product?.attributes?.sales_package_sizes ? Math.floor(product?.attributes?.sales_package_sizes) : 1,
    assortedQuantity: undefined,
    touched: false,
  }));

const ProductsTable: FC<IProductsTable> = (props) => {
  const { onFetchCrossReferences, products = [], onQuantitiesChange } = props;
  const { t } = useTranslation('PRODUCTS');
  const [{ token }] = useAuth();

  const [productQuantities, setProductQuantities] = useState<IProductQuantity[]>(initializeQuantities(products));

  const { data, isLoading } = useQuery(
    ['products-final-prices', products],
    async () => {
      return await getProductsFinalPrices({
        token: token!,
        items: products.map((product) => ({
          productId: product.id,
          quantity: productQuantities.find((q) => q.id === product.id)?.quantity || 0,
        })),
      });
    },
    {
      staleTime: 1000 * 60 * 5,
      retry: 2,
      keepPreviousData: true,
    },
  );

  useEffect(() => {
    const initialQuantities = initializeQuantities(products);
    setProductQuantities(initialQuantities);
    onQuantitiesChange && onQuantitiesChange(initialQuantities);
  }, [products, onQuantitiesChange]);

  const onQuantityChange = (quantity: IProductQuantity) => {
    let newProductQuantities = [...productQuantities];
    const existingQuantity = newProductQuantities.find((q) => q.id === quantity.id);
    if (existingQuantity) {
      existingQuantity.quantity = quantity.quantity;
    } else {
      newProductQuantities.push(quantity);
    }
    setProductQuantities(newProductQuantities);
    if (onQuantitiesChange) {
      onQuantitiesChange(newProductQuantities);
    }
  };

  const promotions: Map<number, IPromotion> = new Map();
  for (const product of products) {
    const promotion = product.attributes?.promotion;
    promotion && promotions.set(promotion.id, promotion);
  }

  return (
    <div className="overflow-x-auto">
      <div className="min-w-full py-2 align-middle">
        <div className="overflow-x-auto border border-gray-300 shadow rounded-lg">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th scope="col" className="relative py-3 pl-2">
                  <span className="sr-only" />
                </th>
                <th
                  scope="col"
                  className="px-4 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                >
                  {t('PRODUCT')}
                </th>
                <th
                  scope="col"
                  className="px-4 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                >
                  {t('BRAND')}
                </th>
                <th
                  scope="col"
                  className="px-4 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                >
                  {t('STOCK')}
                </th>
                <th
                  scope="col"
                  className="px-4 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                >
                  {t('PRICE')}
                </th>
                <th
                  scope="col"
                  className="px-4 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                >
                  {t('PRICE_NET')}
                </th>
                <th
                  scope="col"
                  className="px-4 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                >
                  {t('FINAL_PRICE')}
                </th>
                <th
                  scope="col"
                  className="sticky right-0 z-5 bg-gray-50 px-4 py-3 text-right text-xs font-medium uppercase tracking-wider text-gray-500 before:absolute before:top-0 before:left-0 before:h-full before:w-[1px] before:bg-gray-300 after:absolute after:top-0 after:right-0 after:h-full after:w-[1px] after:bg-gray-300"
                >
                  {t('ACTION')}
                </th>
              </tr>
            </thead>
            <tbody className="bg-white">
              {products.map((product) => {
                const productQuantity = productQuantities.find((q) => q.id === product.id);
                return (
                  <SingleProduct
                    key={product.id}
                    product={product}
                    onFetchCrossReferences={onFetchCrossReferences}
                    onQuantityChange={onQuantityChange}
                    assortedQuantity={productQuantity?.assortedQuantity || 0}
                    touched={productQuantity?.touched}
                    fetchedPromoPrices={data?.find((p) => p.productId === product.id)}
                    isFetchingPromoPrices={isLoading}
                  />
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default ProductsTable;
