import axios from 'axios';

interface Params {
  promoApplicationId: string;
  token: string;
}

const instance = axios.create({
  baseURL: `${process.env.REACT_APP_BACKEND_URL}/promo-applications`,
});

const removeGiftProduct = async (params: Params) => {
  const { promoApplicationId, token } = params;
  await instance.post(
    `/${promoApplicationId}/remove-gift-product`,
    {},
    { headers: { Authorization: `Bearer ${token}` } },
  );
};

export default removeGiftProduct;
