import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import MessageAlert from '../../components/Alert/MessageAlert';
import FullScreenLoading from '../../components/FullScreenLoading';
import { IFrameBannerProfileBottom } from '../../components/IFrameBanner/ProfileBottom';
import { Sidebar } from '../../components/Sidebar';
import i18n from '../../i18n';
import fetchNewsCategories from '../../lib/fetch/newsposts/categories';
import { NewsPostCategory } from '../../lib/models/NewsPost';
import { PaginatedResponse } from '../../lib/models/PaginatedResponse';
import { useAlert } from '../../providers/AlertProvider';
import { useAuth } from '../../providers/AuthProvider';
import { NewsBreadcrumb } from './NewsBreadcrumb';
import { ChevronRightIcon } from '@heroicons/react/24/solid';
import NewsList from './NewsList';
import { LottieComponent } from '../../components/NoResults';
import NoResultsLottie from '../../lotties/no-results.json';

export const NewsCategories = () => {
  const [{ token }] = useAuth();
  const { t } = useTranslation('COMMON');
  const { t: tNews } = useTranslation('NEWS');
  const [, dispatchAlertChange] = useAlert();
  const [selectedCategory, setSelectedCategory] = useState<NewsPostCategory | null>(null);

  const onSuccess = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const onError = () => {
    dispatchAlertChange({ open: true });
  };

  const {
    data: newsCategories,
    isError,
    isLoading,
    refetch,
  } = useQuery<PaginatedResponse<NewsPostCategory>>(
    'newspostscategories',
    () => fetchNewsCategories(token, { page: 1, limit: 20, sort: ['name:asc'] }),
    {
      onSuccess,
      onError,
    },
  );

  const lang = i18n.languages[0];
  useEffect(() => {
    refetch();
  }, [lang]);

  return (
    <div className="flex flex-col">
      <NewsBreadcrumb categoryName={selectedCategory?.attributes?.name} setSelectedCategory={setSelectedCategory} />

      {isError && (
        <div className="flex-grow flex items-center justify-center py-6 px-4 lg:pr-0">
          <div className="mx-auto max-w-xl text-center">
            <MessageAlert text={t('GENERIC_ERROR')} />
          </div>
        </div>
      )}
      <div className="flex-grow mx-auto w-full px-4">
        <div className="min-h-screen min-w-0 flex-1 bg-white lg:flex lg:flex-row">
          {isLoading && <FullScreenLoading loading title={t('LOADING')} subtitle={t('LOADING_SUBTITLE')} />}
          <div className="flex flex-col lg:flex-row w-full">
            <div className="w-full lg:w-max px-4 order-2 lg:order-1">
              <Sidebar />
            </div>

            <div className="w-full pt-6 order-1 lg:order-2 flex-grow">
              <div className="flex flex-col ml-4 h-full">
                {!isError &&
                  (!newsCategories?.data.length ||
                    (selectedCategory && !selectedCategory.attributes.news.data.length)) && (
                    <div className="flex items-start justify-center px-4 lg:px-0">
                      <div className="mx-auto max-w-xl text-center">
                        <LottieComponent lottie={NoResultsLottie} text={tNews('NO_CATEGORY_NEWS')} />
                        {/* <MessageAlert text={tNews('NO_CATEGORY_NEWS')} /> */}
                      </div>
                    </div>
                  )}
                {!selectedCategory && newsCategories && newsCategories.data.length > 0 && (
                  <div className="flex-grow">
                    <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3">
                      {newsCategories.data.map((category) => {
                        const hasNews = category.attributes.news.data.length > 0;
                        return (
                          <div
                            key={category.id}
                            className={`${
                              hasNews ? 'bg-gray-100 hover:shadow-md cursor-pointer' : 'bg-gray-200 cursor-not-allowed'
                            } shadow-sm overflow-hidden rounded-full flex items-center justify-between p-4`}
                            onClick={() => hasNews && setSelectedCategory(category)}
                          >
                            <div className="flex items-center space-x-4">
                              <div className="font-bold text-xl text-gray-500">{category.attributes.name}</div>
                            </div>
                            <div className="flex items-center justify-between gap-4 px-4 py-4">
                              <span
                                className={`${
                                  hasNews ? 'bg-[#96B0C1]' : 'bg-gray-500'
                                } min-w-9 flex items-center justify-center px-2 py-1 rounded-full text-xl font-semibold ml text-white mr-2`}
                              >
                                {category.attributes.news.data.length}
                              </span>
                              <ChevronRightIcon
                                className="-mr-1 hidden h-5 w-5 lg:inline text-gray-500"
                                aria-hidden="true"
                              />
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}

                {selectedCategory && <NewsList categoryId={selectedCategory.id} />}

                <IFrameBannerProfileBottom />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
