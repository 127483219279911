import { useContext } from 'react';
import AddToCartModal from '../../components/Modals/AddToCartModal';
import StockExceededModal from '../../components/Modals/StockExceededModal';
import { useTranslation } from 'react-i18next';
import FreeItemsDialog from '../../components/Header/ShoppingCart/FreeItemsDialog';
import ConfirmModal from '../../components/Modals/ConfirmModal';
import { CartContext } from './context';
import useCartUtilities from '../../hooks/cart/useCartUtilities';
const CartProvider = ({ children }: { children: React.ReactElement }) => {
  const { t } = useTranslation();

  const {
    onAddToCart,
    isInCartModalOpen,
    toggleIsInCartModal,
    handleConfirm,
    onAddAll,
    currentProduct,
    currentQuantity,
    isStockExceededModalOpen,
    toggleStockExceededModal,
    productStock,
    onAddToCartStockExceeded,
    cartCurrentQuantity,
    fetchCart,
    cartState,
    dispatchStateCart,
    onFlushCart,
    onItemRemove,
    onItemQuantityChange,
    dispatchOverlayChange,
    isOverlayOpen,
    freeCartItem,
    onSearchAddToCart,
    onExcerptChange,
    onSelectedForOderChange,
    toggleFlushCartModal,
    isFlushCartModalOpen,
    flushCartWithConfirm,
    selectAllForOrder,
    isOverlayEnabled,
    setIsOverlayEnabled,
    adding,
  } = useCartUtilities();

  return (
    <CartContext.Provider
      value={{
        cartState,
        dispatchState: dispatchStateCart,
        addToCart: onAddToCart,
        fetchCart,
        onAddAll,
        onFlushCart,
        onItemRemove,
        onItemQuantityChange,
        dispatchOverlayChange,
        isOverlayOpen,
        onSearchAddToCart,
        onExcerptChange,
        onSelectedForOderChange,
        flushCartWithConfirm,
        selectAllForOrder,
        isOverlayEnabled,
        setIsOverlayEnabled,
        adding,
      }}
    >
      {children}
      <AddToCartModal
        isOpen={isInCartModalOpen}
        onRequestClose={toggleIsInCartModal}
        onConfirm={handleConfirm}
        product={currentProduct}
        quantity={currentQuantity}
      />
      {productStock && currentProduct && (
        <StockExceededModal
          toggleModal={toggleStockExceededModal}
          onAddToCart={onAddToCartStockExceeded}
          isOpen={isStockExceededModalOpen}
          productStock={productStock}
          requestedQuantity={currentQuantity}
          cartCurrentQuantity={cartCurrentQuantity}
        />
      )}
      {/*cartState.isLoading && (
        <FullScreenLoading
          loading={cartState.isLoading}
          lottie={ShoppingCartLottie}
          title={t('SHOPPING_CART:UPDATING_TITLE')}
          subtitle={t('SHOPPING_CART:UPDATING_SUBTITLE')}
          height={200}
          width={400}
        />
      )*/}

      <FreeItemsDialog cartItem={freeCartItem} />
      <ConfirmModal
        isOpen={isFlushCartModalOpen}
        onRequestClose={toggleFlushCartModal}
        onConfirm={onFlushCart}
        title={t('SHOPPING_CART:FLUSH_CART_TITLE')}
        confirmText={t('SHOPPING_CART:FLUSH_CART_CONFIRM')}
        message={t('SHOPPING_CART:FLUSH_CART_MESSAGE')}
      />
    </CartContext.Provider>
  );
};

const useCart = () => useContext(CartContext);

export { CartProvider, useCart };
