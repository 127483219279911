import { useMemo } from 'react';
import { IProductAttributes } from '../../../lib/fetch/products';
import { getMediaUrl } from '../../../lib/utils';
import { useTranslation } from 'react-i18next';

interface Props {
  product: IProductAttributes & { id: number };
  giftQuantity: number;
  selectedProductId: number;
  onSelectProduct: (productId: number) => void;
}

const ChoiceProduct: React.FunctionComponent<Props> = (props) => {
  const { product, giftQuantity, selectedProductId, onSelectProduct } = props;

  const { t } = useTranslation('SHOPPING_CART');

  const isProductChecked = useMemo(
    () => String(selectedProductId) === String(product.id),
    [product, selectedProductId],
  );

  return (
    <div
      className="grid grid-cols-4 items-center p-2 px-4 border-gray-200 border-t cursor-pointer hover:bg-gray-50"
      onClick={() => {
        onSelectProduct(product.id);
      }}
    >
      <div className="flex items-center gap-2">
        <input
          type="radio"
          name="product"
          onChange={() => {
            onSelectProduct(product.id);
          }}
          checked={isProductChecked}
          readOnly
          className="h-4 w-4 border-gray-300 text-red-600 focus:ring-red-500"
        />
        <span className="text-xs font-semibold">{giftQuantity}x</span>
        <img src={getMediaUrl(product.thumbnail)} alt={product.code} className="h-12 w-12 object-cover" />
      </div>

      <div>
        <p className="font-medium text-sm">{product.code}</p>
        <p className="text-gray-500 text-xs">{product.description}</p>
      </div>
      <div className="flex justify-center">
        <img src={getMediaUrl(product.thumbnail)} alt={product.code} className="h-12 w-12 object-cover" />
      </div>
      <span className="font-semibold text-end">{t('FREE')}</span>
    </div>
  );
};

export default ChoiceProduct;
