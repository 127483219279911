import { useQuery } from 'react-query';
import getCustomers from '../../lib/fetch/agent/getCustomers';
import { ICustomerForAgentResponse } from '../../lib/interfaces/ICustomerForAgent';

interface UseAgentCustomersProps {
  token: string;
  filters: Record<string, string>;
  page: number;
  pageSize: number;
}

const useAgentCustomers = ({ token, filters, page, pageSize }: UseAgentCustomersProps) => {
  return useQuery<ICustomerForAgentResponse>({
    queryKey: ['customers', filters, page, pageSize],
    queryFn: () => getCustomers(token, { ...filters, page, pageSize }),
    keepPreviousData: true,
  });
};

export default useAgentCustomers;
