import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation, Autoplay, Mousewheel } from 'swiper/modules';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/thumbs';
import 'swiper/css/effect-coverflow';
import 'swiper/css/mousewheel';
import 'swiper/css/autoplay';
import 'swiper/css/effect-fade';
import 'swiper/css/grid';

import './carousel.css';
import { Link } from 'react-router-dom';
import { ROUTES } from '../../../routes';
import { IPromotion } from '../../../lib/fetch/products';
import { useTranslation } from 'react-i18next';
import { getMediaUrl } from '../../../lib/utils';
import PLACEHOLDER_PROMO from './placeholder_promo.png';
import moment from 'moment';
import { ArrowRightIcon } from '@heroicons/react/24/solid';

interface IPromomotionsCarousel {
  promotions: IPromotion[] | [];
}

const PromotionCarousel: React.FC<IPromomotionsCarousel> = (props: IPromomotionsCarousel) => {
  const { promotions } = props;
  const { t } = useTranslation('PROMOTIONS_CAROUSEL');
  const { t: tPromotions } = useTranslation('PROMOTION_DETAIL');

  return (
    <section className="w-full bg-gray-50 py-2 pt-4">
      <div className="px-2 sm:px-4 max-w">
        <Link
          to={ROUTES.PROMOTIONS.LIST}
          className="text-center text-xl font-semibold text-gray-700 mb-4 block hover:underline"
        >
          {t('OUR_PROMOS')}
        </Link>
        <Swiper
          modules={[Pagination, Navigation, Autoplay, Mousewheel]}
          mousewheel={true}
          centeredSlides={false}
          watchOverflow={true}
          className="w-full"
          pagination={{
            enabled: true,
            clickableClass: 'swiper-pagination-clickable carousel-bullets-wrapper',
            dynamicBullets: true,
            clickable: true,
          }}
          autoplay={{
            pauseOnMouseEnter: true,
          }}
          grabCursor={promotions.length > 1}
          breakpoints={{
            320: {
              slidesPerView: 1,
              spaceBetween: 10,
              slidesPerGroup: 1,
            },
            640: {
              slidesPerView: 2,
              spaceBetween: 15,
              slidesPerGroup: 2,
            },
            768: {
              slidesPerView: 3,
              spaceBetween: 20,
              slidesPerGroup: 3,
            },
            1280: {
              slidesPerView: 4,
              spaceBetween: 20,
              slidesPerGroup: 4,
            },
          }}
          loop={false}
        >
          {promotions.map((p, index) => {
            return (
              <SwiperSlide key={index}>
                <Link to={`${ROUTES.PROMOTIONS.PROMO}/${p.id}`} key={index}>
                  <div className="relative">
                    {!p.image?.url && (
                      <>
                        <h3 className="absolute font-bold text-lg text-gray-800 ml-4 mt-4">{p.title}</h3>
                        <h5 className="absolute font-semibold text-xs text-gray-800 ml-4 mt-12">{p.description}</h5>
                      </>
                    )}
                    <img
                      src={p.image?.url ? getMediaUrl(p.image?.url) : PLACEHOLDER_PROMO}
                      alt={p.image?.name ?? ''}
                      className="w-full max-h-40 sm:max-h-48 rounded-xl"
                    />
                    <div className="flex flex-row justify-between mt-1 mb-1">
                      <div
                        className="text-gray-800 px-3 py-1 rounded-xl text-[13px] sm:text-xs inline-block mb-1"
                        dangerouslySetInnerHTML={{
                          __html: tPromotions('PROMOTION_VALIDITY_PERIOD', {
                            from: moment(p.validFrom).format('DD/MM/YYYY'),
                            to: moment(p.validTo).format('DD/MM/YYYY'),
                          }),
                        }}
                      />
                      <ArrowRightIcon className="h-6 w-6 mr-2 text-red-500" aria-hidden="true" />
                    </div>
                  </div>
                </Link>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    </section>
  );
};

export default PromotionCarousel;
