import { useEffect, useState } from 'react';
import { currency } from '../../../lib/utils';
import Spinner from '../../Spinner';
import { useTranslation } from 'react-i18next';

interface PriceDisplayProps {
  basePrice: number;
  finalPrice: number;
  isPromo: boolean;
  isLoading?: boolean;
  discountPercentage?: number;
}

const PriceDisplay = ({ basePrice, finalPrice, isPromo, isLoading, discountPercentage }: PriceDisplayProps) => {
  const { t } = useTranslation();
  const [delayedLoading, setDelayedLoading] = useState(false);

  //il prezzo viene caricato con un delay di 300ms per evitare il flickering
  useEffect(() => {
    if (isLoading) {
      setDelayedLoading(true);
    } else {
      const timer = setTimeout(() => {
        setDelayedLoading(false);
      }, 300);

      return () => clearTimeout(timer);
    }
  }, [isLoading]);

  return (
    <>
      {delayedLoading ? (
        <Spinner style={{ color: 'red' }} />
      ) : isPromo ? (
        <>
          <div className="flex items-center gap-2">
            <p className="line-through text-gray-500">{currency(basePrice) ?? '-'}</p>
            <p className="font-bold text-red-600 bg-gray-100 rounded-md px-2 py-1">{currency(finalPrice) ?? '-'}</p>
          </div>
          {discountPercentage && (
            <p className="text-[10px]">
              {t('PRODUCTS:DISCOUNT_MESSAGE')}: {currency(basePrice - finalPrice)}
            </p>
          )}
        </>
      ) : (
        <p className="text-gray-700">{currency(basePrice) ?? '-'}</p>
      )}
    </>
  );
};

export default PriceDisplay;
