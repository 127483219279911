import { useQuery } from 'react-query';
import { useAlert } from '../../../providers/AlertProvider';
import { useAuth } from '../../../providers/AuthProvider';
import { useEffect } from 'react';
import FullScreenLoading from '../../../components/FullScreenLoading';
import { useTranslation } from 'react-i18next';
import MessageAlert from '../../../components/Alert/MessageAlert';
import i18n from '../../../i18n';
import { Sidebar } from '../../../components/Sidebar';
import fetchPromotions from '../../../lib/fetch/promotions/list';
import { IFrameBannerProfileBottom } from '../../../components/IFrameBanner/ProfileBottom';
import { AxiosResponse } from 'axios';
import { IPromotion } from '../../../lib/fetch/products';
import { Link } from 'react-router-dom';
import { ROUTES } from '../../../routes';
import Breadcrumbs from '../../../components/Breadcrumbs';
import { getMediaUrl } from '../../../lib/utils';

import PLACEHOLDER_PROMO from '../../../components/News/PromotionCarousel/placeholder_promo.png';
import moment from 'moment';
import { ArrowRightIcon } from '@heroicons/react/24/outline';
import NoResultsLottie from '../../../lotties/no-results.json';
import { LottieComponent } from '../../../components/NoResults';

const PromotionsList = () => {
  const [{ token }] = useAuth();
  const [, dispatchAlertChange] = useAlert();
  const { t } = useTranslation('COMMON');
  const { t: tPromotions } = useTranslation('PROMOTION_DETAIL');
  const { t: tProducts } = useTranslation('PRODUCTS');

  const onSuccess = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const onError = () => {
    dispatchAlertChange({ open: true });
  };
  const {
    data: promotions,
    isError,
    isLoading,
    refetch,
  } = useQuery<AxiosResponse<IPromotion[]>>('promotions', () => fetchPromotions(token), {
    onSuccess,
    onError,
  });

  const lang = i18n.languages[0];
  useEffect(() => {
    refetch();
  }, [lang]);

  const steps = [
    {
      name: 'Promotions',
      to: ROUTES.PROMOTIONS.LIST,
      current: true,
    },
  ];
  return (
    <div className="flex flex-col">
      <Breadcrumbs steps={steps} />
      {isError && (
        <div className="flex-grow flex items-center justify-center py-6 px-4 lg:pr-0">
          <div className="mx-auto max-w-xl text-center">
            <MessageAlert text={t('GENERIC_ERROR')} />
          </div>
        </div>
      )}
      <div className="flex-grow mx-auto w-full px-4">
        <div className="min-h-screen min-w-0 flex-1 bg-white lg:flex lg:flex-row">
          {isLoading && <FullScreenLoading loading title={t('LOADING')} subtitle={t('LOADING_SUBTITLE')} />}

          <div className="flex flex-col lg:flex-row w-full">
            <div className="w-full lg:w-max px-4 order-2 lg:order-1">
              <Sidebar />
            </div>

            <div className="w-full pt-6 order-1 lg:order-2 flex-grow">
              <div className="flex flex-col ml-4 h-full">
                {promotions && (
                  <div className="flex-grow">
                    {promotions?.data.length === 0 && (
                      <div className="flex items-start justify-center px-4 lg:px-0">
                        <div className="mx-auto max-w-xl text-center">
                          <LottieComponent lottie={NoResultsLottie} text={tProducts('PROMOTION_UNAVAILABLE')} />
                        </div>
                      </div>
                    )}
                    <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3">
                      {promotions?.data.map((promotion, index) => (
                        <Link to={`${ROUTES.PROMOTIONS.PROMO}/${promotion.id}`} key={index}>
                          <div className="flex flex-col gap-4">
                            <div className="relative rounded-xl overflow-hidden shadow-md">
                              <div className="flex flex-col">
                                {!promotion.image?.url && (
                                  <>
                                    <h3 className="absolute font-bold text-lg text-gray-800 ml-4 mt-4">
                                      {promotion.title}
                                    </h3>
                                    <h5 className="absolute font-semibold text-xs text-gray-800 ml-4 mt-12">
                                      {promotion.description}
                                    </h5>
                                  </>
                                )}
                                <img
                                  src={promotion.image?.url ? getMediaUrl(promotion.image?.url) : PLACEHOLDER_PROMO}
                                  alt={promotion.image?.name || 'Promotion Image'}
                                  className="w-full h-48 rounded-xl"
                                />
                                <div className="flex flex-row justify-between mt-1 mb-1">
                                  <div
                                    className="bg-white/90 text-gray-800 px-3 py-1 rounded-xl text-[13px] sm:text-xs inline-block mb-1"
                                    dangerouslySetInnerHTML={{
                                      __html: tPromotions('PROMOTION_VALIDITY_PERIOD', {
                                        from: moment(promotion.validFrom).format('DD/MM/YYYY'),
                                        to: moment(promotion.validTo).format('DD/MM/YYYY'),
                                      }),
                                    }}
                                  />
                                  <ArrowRightIcon className="h-6 w-6 mr-2 text-red-500" aria-hidden="true" />
                                </div>
                              </div>
                            </div>
                          </div>
                        </Link>
                      ))}
                    </div>
                  </div>
                )}
                <IFrameBannerProfileBottom />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PromotionsList;
