import { Disclosure } from '@headlessui/react';
import { ChevronRightIcon } from '@heroicons/react/24/outline';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { getThumbnail } from '.';
import { HIDDEN_MANUFACTURERS_NAMES } from '../../lib/constants';
import { IProduct } from '../../lib/fetch/products';
import { currency } from '../../lib/utils';
import { getTecdocManufacturerById, getTecdocManufacturerByName } from '../../lib/utils/manufacturers';
import { useSettings } from '../../providers/SettingsProvider';
import { MadeOrderBadge, OutProductionBadge, PiecesBadge, UnavailableBadge, WarehouseModal } from '../StockBadge';
import AddCartField from './AddCartField';
import DetailsDisclousre from './DetailsDisclosure';
import moment from 'moment';
import { useCart } from '../../providers/CartProvider';
import { InformationCircleIcon } from '@heroicons/react/24/solid';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { ROUTES } from '../../routes';
import getProductsFinalPrices from '../../lib/fetch/products/getProductsFinalPrices';
import { useMutation } from 'react-query';
import { useAuth } from '../../providers/AuthProvider';
import { useAlert } from '../../providers/AlertProvider';
import { IGetProductsFinalPricesResponse } from '../../lib/fetch/products/getProductsFinalPrices';
import { useProductPrices } from '../../hooks/product/useProductPrices';
import PriceDisplay from './PriceDisplay/PriceDisplay';
interface SingleProductProps {
  product: IProduct;
  onFetchCrossReferences?: ((productId: number) => Promise<IProduct[]>) | undefined;
  onQuantityChange?: (quantity: { id: number; quantity: number; assortedQuantity?: number; touched?: boolean }) => void;
  assortedQuantity?: number;
  touched?: boolean;
  crossReference?: boolean;
  fetchedPromoPrices?: IGetProductsFinalPricesResponse;
  isFetchingPromoPrices?: boolean;
}

export const SingleProduct = (props: SingleProductProps) => {
  const {
    product,
    onFetchCrossReferences = null,
    onQuantityChange = null,
    crossReference = false,
    fetchedPromoPrices,
    isFetchingPromoPrices = false,
  } = props;

  const [{ token }] = useAuth();
  const [{ tecdocManufacturers }] = useSettings();
  const { t } = useTranslation('PRODUCTS');
  const [, dispatchAlertChange] = useAlert();
  const [quantity, setQuantity] = useState(
    product?.attributes?.sales_package_sizes ? Math.floor(product?.attributes?.sales_package_sizes) : 1,
  );

  const {
    addToCart,
    cartState: { itemAdding, isAddingBulk },
  } = useCart();
  const [promoPrice, setPromoPrice] = useState(fetchedPromoPrices);

  const { basePrice, finalPrice, isPromo, discountPercentage } = useProductPrices({
    promoPrice,
    quantity,
  });

  useEffect(() => {
    onQuantityChange?.({ id: product.id, quantity, touched: true });
  }, [quantity]);

  //Appena il primo fetch nel componente padre va in porto, setta il valore di promoPrice
  useEffect(() => {
    setPromoPrice(fetchedPromoPrices);
  }, [fetchedPromoPrices]);

  const minQuantity = useMemo(
    () => (product?.attributes?.sales_package_sizes ? Math.floor(product?.attributes?.sales_package_sizes) : 1),
    [product],
  );

  const mutation = useMutation({
    mutationFn: getProductsFinalPrices,
    onSuccess: (data) => {
      setPromoPrice(data[0]);
    },
    onError: (error) => {
      dispatchAlertChange({
        open: true,
        message: t('PRODUCTS:FINAL_PRICE_LOADING_ERROR'),
      });
    },
  });

  return (
    <Disclosure key={product.id}>
      {({ open }) => (
        <>
          <tr className="border-t">
            {!crossReference && (
              <td className="py-2 pl-2">
                <Disclosure.Button className="bg-white text-gray-600 focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75">
                  <ChevronRightIcon className={`${open ? 'rotate-90 transform' : ''} h-4 w-4`} />
                </Disclosure.Button>
              </td>
            )}
            <td className="whitespace-nowrap py-2 pl-2 pr-4">
              <div className="flex items-center">
                {getThumbnail(product.tecdoc?.images[0]?.imageURL3200 || product.attributes.thumbnail)}
                <Link to={`${ROUTES.SEARCH.PRODUCT_DETAIL}/${product.id}`} className="ml-3">
                  <div className="text-sm font-medium text-gray-900">{product.attributes.code}</div>
                  <div className="text-sm font-normal text-gray-500 max-w-xs truncate">
                    {product.attributes.description ||
                      product.tecdoc?.genericArticles[product.tecdoc?.genericArticles.length - 1]
                        ?.genericArticleDescription ||
                      product.attributes.family?.data.attributes.name}
                  </div>
                </Link>
              </div>
            </td>
            <td className="whitespace-nowrap px-4 py-2">
              {product.tecdoc?.dataSupplierId &&
              getTecdocManufacturerById(product.tecdoc?.dataSupplierId, tecdocManufacturers) ? (
                <img
                  className="h-auto w-20"
                  src={
                    getTecdocManufacturerById(product.tecdoc?.dataSupplierId, tecdocManufacturers)!.dataSupplierLogo
                      .imageURL800
                  }
                  alt=""
                />
              ) : product.attributes.manufacturer?.data.attributes.name &&
                getTecdocManufacturerByName(
                  product.attributes.manufacturer?.data.attributes.name,
                  tecdocManufacturers,
                ) ? (
                <img
                  className="h-auto w-20"
                  src={
                    getTecdocManufacturerByName(
                      product.attributes.manufacturer?.data.attributes.name,
                      tecdocManufacturers,
                    )!.dataSupplierLogo.imageURL800
                  }
                  alt=""
                />
              ) : product.attributes.manufacturer?.data.attributes.name &&
                !HIDDEN_MANUFACTURERS_NAMES.includes(
                  `${product.attributes.manufacturer?.data.attributes.name}`.toUpperCase(),
                ) ? (
                product.attributes.manufacturer.data.attributes.name
              ) : (
                product.attributes.manufacturer?.data.id || '-'
              )}
            </td>
            <td className="whitespace-nowrap px-4 py-2 text-sm text-gray-500">
              {!product.attributes.active && <OutProductionBadge />}
              {product.attributes.active &&
                product.attributes.quantity.quantityDefaultWarehouse === 0 &&
                product.attributes.quantity.quantityOtherWarehouses === 0 && <UnavailableBadge />}
              {product.attributes.active && product.attributes.quantity.quantityDefaultWarehouse > 0 && (
                <PiecesBadge quantity={product.attributes.quantity.quantityDefaultWarehouse} />
              )}
              {product.attributes.active &&
                product.attributes.quantity.quantityDefaultWarehouse === 0 &&
                product.attributes.quantity.quantityOtherWarehouses > 0 && (
                  <>
                    <MadeOrderBadge quantity={product.attributes.quantity.quantityOtherWarehouses} />
                    <WarehouseModal otherWarehouses={product.attributes.quantity.otherWarehouses} />
                  </>
                )}
            </td>
            <td className="whitespace-nowrap px-4 py-2 text-sm font-semibold text-gray-700">
              {product.attributes.price_list ? <p>{currency(product.attributes.price_list)}</p> : '-'}
            </td>
            <td className="whitespace-nowrap px-4 py-2 text-sm font-semibold text-gray-700">
              {(Boolean(product.attributes.cost_additional) || Boolean(product.attributes.cost_delivery)) && (
                <div className="flex flex-col">
                  <InformationCircleIcon className="w-5 mr-1" data-tooltip-id="tooltip-cost-additional" />{' '}
                  <ReactTooltip
                    id="tooltip-cost-additional"
                    place="top"
                    content={`${t('COST_ADDITIONAL')} ${currency((product?.attributes?.cost_additional ?? 0) + (product?.attributes?.cost_delivery ?? 0))}`}
                    style={{ background: '#3A4150 0% 0% no-repeat padding-box', opacity: 1, borderRadius: '8px' }}
                  />
                </div>
              )}
              <div className="flex flex-col">
                {product.attributes.price_net ? (
                  <>
                    <p>{currency(product.attributes.price_net)}</p>
                    {minQuantity > 1 && (
                      <p className="text-xs">
                        {t('MIN_QUANTITY')}
                        {minQuantity}
                      </p>
                    )}
                    {product.attributes?.mpcExpire && (
                      <p className="text-xs text-red-600">
                        {t('NET_AVAILABILITY')}
                        {moment(product?.attributes?.mpcExpire, 'YYYY-MM-DD').format('DD/MM/YYYY')}
                      </p>
                    )}
                    {!!product.attributes.promotion && (
                      <p className="text-xs font-bold text-red-600">{product.attributes.promotion.listTitle}</p>
                    )}
                  </>
                ) : (
                  '-'
                )}
              </div>
            </td>
            <td className="whitespace-nowrap px-4 py-2 text-sm font-semibold text-gray-700">
              <PriceDisplay
                basePrice={basePrice}
                finalPrice={finalPrice}
                isPromo={isPromo}
                isLoading={isFetchingPromoPrices || mutation.isLoading}
                discountPercentage={discountPercentage ?? undefined}
              />
            </td>
            <td className="sticky right-0 z-5 bg-white whitespace-nowrap px-4 py-2 text-right text-sm font-medium w-32 before:absolute before:top-0 before:left-0 before:h-full before:w-[1px] before:bg-gray-300 after:absolute after:top-0 after:right-0 after:h-full after:w-[1px] after:bg-gray-300">
              <AddCartField
                onAdd={(quantity) => addToCart({ product, quantity, displayAddToCartModal: true })}
                product={product}
                setQuantity={setQuantity}
                adding={itemAdding[product.id]}
                isAddingBulk={isAddingBulk}
                onQuantityChange={(quantity) => {
                  mutation.mutate({
                    token: token!,
                    items: [{ productId: product.id, quantity: quantity }],
                  });
                }}
              />
            </td>
          </tr>

          {onFetchCrossReferences && (
            <tr className="border-0">
              <td colSpan={7} className="border-0 pr-0">
                <div className="relative">
                  <DetailsDisclousre
                    product={product}
                    promotion={product.attributes.promotion}
                    showTechnicalSpecs={true}
                    showCrossReferences={true}
                    onFetchCrossReferences={onFetchCrossReferences}
                  />
                </div>
              </td>
              <td className="sticky right-0 z-5 bg-white w-32 before:absolute before:top-0 before:left-0 before:h-full before:w-[1px] before:bg-gray-300 after:absolute after:top-0 after:right-0 after:h-full after:w-[1px] after:bg-gray-300"></td>
            </tr>
          )}
        </>
      )}
    </Disclosure>
  );
};
