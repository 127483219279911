import { Link } from 'react-router-dom';
import {
  AtSymbolIcon,
  CursorArrowRaysIcon,
  EnvelopeIcon,
  PhoneArrowUpRightIcon,
  PhoneIcon,
} from '@heroicons/react/24/solid';
import { useTranslation } from 'react-i18next';
import packageDotJSON from '../../../package.json';
import { useAuth } from '../../providers/AuthProvider';

import AgentPlaceholder from './AgentPlaceholder.svg';

const { version } = packageDotJSON;

const Footer = () => {
  const { t } = useTranslation('FOOTER');
  const [{ user }] = useAuth();

  const agent = [
    {
      key: 1,
      name: user?.customer?.agent_id?.name || t('NO_AGENT'),
      image: AgentPlaceholder,
      phone: '+39 345 111 222',
      email: user?.customer?.agent_id?.email || t('NO_AGENT_MAIL'),
      whatsapp: 'https://wa.me/39345111222',
      callMeBack: 'tel:+39345111222',
      role: t('YOUR_AGENT'),
    },
    {
      key: 2,
      name: t('SERVICE_ASSISTANCE'),
      image: AgentPlaceholder,
      phone: '+39 011 4073 711',
      email: 'assistenzaconsegne@cati.it',
      whatsapp: 'https://wa.me/390114073711',
      callMeBack: 'tel:+390114073711',
      role: t('SUPPORT'),
    },
  ];

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <>
      <div className="bg-[#96B0C1] p-10 py-16 justify-center flex flex-col items-center">
        <h1 className="text-3xl font-bold text-white">{t('NEED_HELP')}</h1>
        <p className="text-[#606E81] text-xl text-center">{t('GET_IN_TOUCH')}</p>
        <div className="flex md:flex-row flex-col gap-12 mt-12 max-w-[1000px] w-full">
          {agent.map((agente) => (
            <div className="w-full" key={agente.key}>
              <p className="text-center text-xl font-semibold text-white uppercase mb-12">{agente.role}</p>
              <div className="bg-white p-4 rounded-lg flex flex-col w-full justify-center items-center">
                <img
                  src={agente.image}
                  alt={agente.name}
                  className="w-24 h-24 object-cover rounded-full bg-slate-200 -mt-12"
                />
                <span className="text-center text-2xl  text-black my-3"> {agente.name}</span>
                <span className="text-md text-gray-800 flex flex-row items-center gap-2">
                  <PhoneIcon className="h-5 w-5" aria-hidden="true" /> {agente.phone}
                </span>
                <a href={`mailto:${agente.email}`} className="text-md text-gray-800 flex flex-row items-center gap-2">
                  <AtSymbolIcon className="h-5 w-5" aria-hidden="true" /> {agente.email}
                </a>
                <div className="flex flex-row gap-4 w-full mt-6">
                  <a
                    href={agente.whatsapp}
                    target="_blank"
                    rel="noreferrer"
                    className="bg-[#045E54] text-[#25D366] rounded-lg p-3 text-sm w-full text-center flex flex-row items-center justify-center gap-2"
                  >
                    <img src={'./images/icons/whatsapp.svg'} alt="WhatsApp" className="h-5 w-5" />
                    <span className="truncate">{t('WHATSAPP_NOW')}</span>
                  </a>
                  <a
                    href={agente.callMeBack}
                    className="bg-blue-400 text-white rounded-lg p-3 text-sm w-full text-center flex flex-row items-center justify-center gap-2"
                  >
                    <PhoneArrowUpRightIcon className="h-6 w-6" aria-hidden="true" />
                    <span className="truncate">{t('CALL_ME_BACK')}</span>
                  </a>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      <footer className="bg-gray-800 text-gray-300">
        <div className="max-w-7xl mx-auto py-12 px-4 lg:py-16 lg:px-8">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
            {/* Company Information */}
            <div className="text-center md:text-left">
              <img
                className="h-7 w-auto mb-4"
                src="../images/cati-logo-white.png"
                alt="Cati Ecommerce"
                title="Cati Ecommerce"
              />
              <ul className="mt-4 space-y-2">
                <li className="text-md font-bold">CATI Spa</li>
                <li className="text-sm">{t('HEADQUARTERS')}</li>
                <li className="text-md">Via Pretegiani 27, 10078 Venaria (TO)</li>
                <li className="text-md font-bold">{t('BRANCHES_ITALY')}</li>
                <li className="text-sm flex">
                  <CursorArrowRaysIcon className="mr-2 h-5 w-5" />
                  <a
                    href="https://www.catispa.com"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="hover:text-white"
                  >
                    www.catispa.com
                  </a>
                </li>
              </ul>
            </div>

            {/* Contact Information */}
            <div className="text-center md:text-left">
              <h3 className="text-sm font-semibold uppercase tracking-wider text-gray-400">{t('CONTACTS')}</h3>
              <ul className="mt-4 space-y-4">
                <li>
                  <a
                    href="tel:+390114073711"
                    className="flex justify-center md:justify-start items-center text-sm hover:text-white"
                  >
                    <PhoneIcon className="mr-2 h-5 w-5" /> +39 011 4073711
                  </a>
                </li>
                <li>
                  <a
                    href="mailto:dashboard@cati.it"
                    className="flex justify-center md:justify-start items-center text-sm hover:text-white font-semibold"
                  >
                    <EnvelopeIcon className="mr-2 h-5 w-5" />
                    {t('WRITE_US')}
                  </a>
                </li>
                <li>
                  <a
                    href="mailto:amministrazione.cati@pec.cati.it"
                    className="flex justify-center md:justify-start items-center text-sm hover:text-white"
                  >
                    PEC: amministrazione.cati@pec.cati.it
                  </a>
                </li>
              </ul>
            </div>

            {/* Legal Information */}
            <div className="text-center md:text-left">
              <h3 className="text-sm font-semibold uppercase tracking-wider text-gray-400">{t('COMPANY_TITLE')}</h3>
              <ul className="mt-4 space-y-2">
                <li className="text-sm">{`${t('VAT')} IT03748340019`}</li>
                <li className="text-sm">{t('REGISTER')}</li>
                <li className="text-sm">{t('SOCIAL_AMOUNT')}</li>
                <li className="text-sm">{t('CHANNEL')}</li>
              </ul>
            </div>

            {/* Links */}
            <div className="text-center md:text-left">
              <ul className="mt-4 space-y-2">
                <li>
                  <Link
                    to="/promotions"
                    className="text-sm hover:text-white"
                    onClick={() => {
                      scrollToTop();
                    }}
                  >
                    {t('PROMOTIONS')}
                  </Link>
                </li>
                <li>
                  <Link
                    to="/search-results"
                    className="text-sm hover:text-white"
                    onClick={() => {
                      scrollToTop();
                    }}
                  >
                    {t('PROVIDERS')}
                  </Link>
                </li>
                <li>
                  <Link
                    to="/search-results"
                    className="text-sm hover:text-white"
                    onClick={() => {
                      scrollToTop();
                    }}
                  >
                    {t('BECOME_CUSTOMER')}
                  </Link>
                </li>
                <li>
                  <Link
                    to="/search-results"
                    className="text-sm hover:text-white"
                    onClick={() => {
                      scrollToTop();
                    }}
                  >
                    {t('SELL_CONDITIONS')}
                  </Link>
                </li>
                <li>
                  <Link
                    to="/search-results"
                    className="text-sm hover:text-white"
                    onClick={() => {
                      scrollToTop();
                    }}
                  >
                    {t('DELIVERY_TIME')}
                  </Link>
                </li>
                <li>
                  <Link
                    to="/profile/return-info"
                    className="text-sm hover:text-white"
                    onClick={() => {
                      scrollToTop();
                    }}
                  >
                    {t('RETURN_POLICY')}
                  </Link>
                </li>
              </ul>
            </div>
          </div>

          <div className="mt-8 flex flex-wrap items-center justify-center border-t border-gray-700 pt-8">
            <p className="text-center text-sm text-gray-400">
              &copy; CATI S.p.A. {new Date().getFullYear()}, All rights reserved.
            </p>

            <span className="ml-4 h-6 w-px bg-gray-400" aria-hidden="true" />

            <p className="ml-4 text-center text-sm text-gray-400">
              <Link
                to="/profile/privacy-policy"
                className="hover:text-white"
                onClick={() => {
                  scrollToTop();
                }}
              >
                {t('PRIVACY:TITLE')}
              </Link>
            </p>

            <span className="ml-4 h-6 w-px bg-gray-400" aria-hidden="true" />

            <p className="ml-4 text-center text-sm text-gray-400">
              <Link
                to="/profile/terms-conditions"
                className="hover:text-white"
                onClick={() => {
                  scrollToTop();
                }}
              >
                {t('TERMS:TITLE')}
              </Link>
            </p>

            <span className="ml-4 h-6 w-px bg-gray-400" aria-hidden="true" />

            <p className="ml-4 text-center text-sm text-gray-400">v. {version}</p>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
