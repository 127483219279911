import Lottie from 'react-lottie-player';
import InfoAlert from '../Alert/InfoAlert';

interface ILottieComponent {
  lottie: any;
  speed?: number;
  height?: number;
  width?: number;
  text: string;
}

export const LottieComponent = ({ lottie, speed = 1, height = 300, width = 300, text }: ILottieComponent) => {
  return (
    <div className="mx-auto max-w-xl text-center">
      <Lottie loop animationData={lottie} speed={speed} play style={{ height, width, margin: 'auto' }} />
      <InfoAlert text={text} />
    </div>
  );
};
