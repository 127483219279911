import { useEffect, useMemo, useState } from 'react';
import { IProduct, IProductAttributes } from '../../lib/fetch/products';
import {
  IDeleteCartItemWithFeedback,
  IFlushCartWithFeedback,
  isProductInCart,
  IUpdateCartWithFeedback,
  updateCartItem,
} from '../../lib/utils/cart';
import { cartUtils, IAddToCartWithFeedback } from '../../lib/utils';
import { useAuth } from '../../providers/AuthProvider';
import { AlertType, useAlert } from '../../providers/AlertProvider';
import { useShoppingCartOverlay } from '../../providers/ShoppingCartOverlayProvider';
import { useTranslation } from 'react-i18next';
import { IProductQuantity } from '../../components/ProductsTable';
import i18n from '../../i18n';
import { useMutation } from 'react-query';
import findProductStock from '../../lib/fetch/products/findProductStock';
import { getCanUserOrder } from '../../components/ProductsTable/utils';
import { quantityInputProps as _quantityInputProps } from '../../lib/utils';
import { ICartItem } from '../../lib/fetch/cartItems';
import { search as callSearchProducts } from '../../lib/fetch/products';
import { SORTING_OPTIONS } from '../../components/ProductsTable/HorizontalFilter';
import { WhereToSearch } from '../../lib/enums';
import { v4 as uuidv4 } from 'uuid';
import selectAllForOrder from '../../lib/fetch/cartItems/selectAllForOrder';
import myCart from '../../lib/fetch/carts/myCart';
import { AddToCartParams, CartActionType } from '../../lib/interfaces/cart';
import useCartState from './useCartState';
import { useCartModals } from './useCartModals';

const useCartUtilities = () => {
  const [{ token, user }, dispatchAuthChange] = useAuth();
  const [, dispatchAlertChange] = useAlert();
  const [{ open: isOverlayOpen }, dispatchCartOverlayChange] = useShoppingCartOverlay();
  const { t } = useTranslation('PRODUCTS');
  const lang = i18n.languages[0];
  const { cartState, dispatchStateCart } = useCartState();

  const [currentProduct, setCurrentProduct] = useState<IProduct | null>(null);
  const [currentQuantity, setCurrentQuantity] = useState<number>(0);
  const [cartCurrentQuantity, setCartCurrentQuantity] = useState<number>(0);

  const [currentCartItem, setCurrentCartItem] = useState<ICartItem | null>(null);
  const [currentProductQuantity, setCurrentProductQuantity] = useState<number>(0);
  const [cartAction, setCartAction] = useState<CartActionType | null>(null);

  const [adding, setAdding] = useState(false);
  const [searching, setSearching] = useState(false);
  const [displayAddToCartModal, setDisplayAddToCartModal] = useState(false);
  const [isOverlayEnabled, setIsOverlayEnabled] = useState(true);

  const {
    isInCartModalOpen,
    toggleIsInCartModal,
    isStockExceededModalOpen,
    toggleStockExceededModal,
    isFlushCartModalOpen,
    toggleFlushCartModal,
  } = useCartModals();

  /**
   * Serve per far uscire il popup per accettare i termini in caso di prodotto regalo.
   * Va bene trovare il primo perché si accetta uno per volta. Quindi il popup deve uscire in automatico appena c'è un articolo omaggio nel carrello.
   * Se non c'è nessun articolo omaggio nel carrello, non deve uscire il popup.
   */
  const freeCartItem = useMemo(
    () => cartState.cart?.cart_items.find((item) => item.free && !item.isTermsAcceptedForGiftProduct),
    [cartState],
  );

  const onAddToCartStockExceeded = (quantity: number) => {
    if (!currentProduct) return;
    if (isProductInCart(cartState.cart, currentProduct) && displayAddToCartModal) {
      toggleIsInCartModal();
      return;
    }
    mutateAddProductToCart({ product: currentProduct, quantity, setAddingState: setAdding });
  };

  const addProductToCart = async (
    product: IProduct,
    quantity: number,
    setAddingState: (adding: boolean) => void = setAdding,
  ) => {
    let quantityForced = quantity;
    const minQuantity = product?.attributes?.sales_package_sizes || 1;

    if (
      product?.attributes?.sales_package_sizes &&
      Math.floor(product?.attributes?.sales_package_sizes) !== 1 &&
      quantity % product.attributes.sales_package_sizes !== 0
    ) {
      if (quantity < minQuantity) {
        quantityForced = minQuantity;
      } else if (quantity % minQuantity !== 0) {
        quantityForced = Math.ceil(quantity / minQuantity) * minQuantity;
      }
    }
    //L'aggiunta del prodotto al carrello viene effettuata solo se la quantità è diversa da quella già presente perché passa dallo useEffect
    resetCurrentProductAndQuantity();

    await cartUtils.addToCartWithFeedback({
      dispatchAlertChange,
      dispatchAuthChange,
      dispatchCartOverlayChange,
      products: [{ product, quantity: quantityForced }],
      setAdding: setAddingState,
      t,
      token,
      user,
      lang: t('LANGUAGE'),
      cart: cartState.cart,
      onClose:
        quantityForced > quantity
          ? () =>
              dispatchAlertChange({
                type: AlertType.Info,
                open: true,
                message: t('SHOPPING_CART:PRODUCT_MIN_QUANTITY') + ` (${minQuantity})`,
              })
          : undefined,
    } as IAddToCartWithFeedback);
    await fetchCart();
  };

  const onAddToCart = async (params: AddToCartParams) => {
    const { product, quantity, displayAddToCartModal = false } = params;
    const uuidFictitious = uuidv4();
    setCurrentProduct({ ...product, uuidFictitious });
    setCurrentQuantity(quantity);
    setDisplayAddToCartModal(displayAddToCartModal);
  };

  const onItemQuantityChange = async (params: UpdateCartItemParams) => {
    const { cartItem, newQuantity } = params;
    setCurrentCartItem(cartItem);
    setCurrentProductQuantity(newQuantity);
    setCartAction(CartActionType.SetItemUpdating);
  };

  const onItemRemove = async (cartItem: ICartItem) => {
    setCurrentCartItem(cartItem);
    setCartAction(CartActionType.SetItemRemoving);
  };

  const handleConfirm = async () => {
    toggleIsInCartModal();
    if (currentProduct && currentQuantity) {
      await mutateAddProductToCart({ product: currentProduct, quantity: currentQuantity, setAddingState: setAdding });
    }
  };

  const onAddAll = async (products: IProduct[], productQuantities: IProductQuantity[]) => {
    setAdding(true);
    const productsWithQuantity = products
      .filter((product) => product?.attributes?.price_list !== 0)
      .map((product) => {
        const quantity =
          productQuantities.find((q) => q.id === product.id)?.quantity || product?.attributes?.sales_package_sizes || 1;
        return { product, quantity };
      });

    await cartUtils.addToCartWithFeedback({
      dispatchAlertChange,
      dispatchAuthChange,
      dispatchCartOverlayChange,
      products: productsWithQuantity,
      setAdding,
      t,
      token,
      user,
      lang,
      cart: cartState.cart,
      onClose: () => setAdding(false),
    } as IAddToCartWithFeedback);
  };

  const onFlushCart = async () => {
    toggleFlushCartModal();
    await cartUtils.flushCartWithFeedback({
      dispatchAlertChange,
      dispatchAuthChange,
      setLoading: setAdding,
      t,
      token,
      cart: cartState.cart,
      user,
      lang,
    } as IFlushCartWithFeedback);
  };

  const flushCartWithConfirm = async () => {
    toggleFlushCartModal();
  };

  const itemRemove = async (cartItem: ICartItem) => {
    await cartUtils.deleteCartItemWithFeedback({
      cartItem,
      dispatchAlertChange,
      dispatchAuthChange,
      setLoading: setAdding,
      t,
      token,
      user,
      cart: cartState.cart,
    } as IDeleteCartItemWithFeedback);
  };

  interface UpdateCartItemParams {
    cartItem: ICartItem;
    newQuantity: number;
  }

  const itemQuantityChange = async ({ cartItem, newQuantity }: UpdateCartItemParams) => {
    const product = cartItem.product;
    let quantityForced = newQuantity;
    const minQuantity = product?.sales_package_sizes || 1;
    if (
      product?.sales_package_sizes &&
      Math.floor(product?.sales_package_sizes) !== 1 &&
      newQuantity % product.sales_package_sizes !== 0
    ) {
      if (newQuantity < minQuantity) {
        quantityForced = minQuantity;
      } else if (newQuantity % minQuantity !== 0) {
        quantityForced = Math.ceil(newQuantity / minQuantity) * minQuantity;
      }
    }

    await cartUtils.updateCartWithFeedback({
      cartItem,
      dispatchAlertChange,
      dispatchAuthChange,
      newQuantity: quantityForced,
      setLoading: setAdding,
      t,
      token,
      user,
      cart: cartState.cart,
      lang,
      onClose:
        quantityForced > newQuantity
          ? () => {
              dispatchAlertChange({
                type: AlertType.Info,
                open: true,
                message: t('SHOPPING_CART:PRODUCT_MIN_QUANTITY') + ' (' + minQuantity + ')',
              });
            }
          : () => {},
    } as IUpdateCartWithFeedback);
  };

  const onSearchAddToCart = async (search: string) => {
    if (!search) return;
    setSearching(true);
    const { data: axiosData, error } = await callSearchProducts(token!, {
      page: 1,
      pageSize: 2, // We just want to know if there is more than ONE product.
      search,
      sort: SORTING_OPTIONS[0].value,
      where: WhereToSearch.Code,
      lang,
    });
    if (error || !axiosData) {
      setSearching(false);
      return dispatchAlertChange({ open: true });
    }
    const { data = [] } = axiosData;
    if (!data.length) {
      setSearching(false);
      return dispatchAlertChange({
        open: true,
        type: AlertType.Info,
        message: t('PRODUCTS:NO_RESULT_FOR_CODE'),
      });
    }
    if (data.length > 1) {
      setSearching(false);

      // Redirect to search page - TEMPORARY SOLUTION
      window.location.href = `/#/search-results?addToCart=0&brand=&categoryId=&categoryName=&search=${search}&sort=price_list%3Aasc&where=code`;

      return dispatchAlertChange({
        open: true,
        type: AlertType.Info,
        message: t('PRODUCTS:ADD_TO_CART_MULTIPLE_ERROR'),
      });
    }
    dispatchCartOverlayChange({ open: false });
    const [product] = data;
    try {
      await onAddToCart({ product, quantity: 1, displayAddToCartModal: true });
    } catch (error) {
      dispatchAlertChange({
        open: true,
        type: AlertType.Error,
        message: t('ADD_TO_CART_ERROR'),
      });
    } finally {
      setSearching(false);
    }
  };

  const onExcerptChange = async (cartItem: ICartItem, excerpt: boolean) => {
    await cartUtils.updateCartWithFeedback({
      cartItem,
      dispatchAlertChange,
      dispatchAuthChange,
      newQuantity: cartItem.quantity,
      excerpt: excerpt,
      setLoading: setAdding,
      t,
      token,
      user,
      cart: cartState.cart,
      lang,
    } as IUpdateCartWithFeedback);
  };

  const onSelectedForOderChange = async (cartItem: ICartItem, selected: boolean) => {
    const product: IProduct = {
      id: cartItem.product.id,
      attributes: {
        ...cartItem.product,
        promotions: cartItem.promotion,
      } as unknown as IProductAttributes,
    };
    updateCartItemMutation({
      token: String(token),
      cartItem,
      product,
      quantity: cartItem.quantity,
      excerpt: cartItem.excerpt,
      selected,
    });
  };

  //USE EFFECTS

  //al cambio del prodotto o quantità, controlla se c'è lo stock e se c'è già nel carrello
  useEffect(() => {
    if (!currentProduct) return;
    mutateProductStock({ token, id: currentProduct.id });
  }, [currentProduct, currentQuantity]);

  useEffect(() => {
    if (!currentCartItem || !cartAction) return;

    switch (cartAction) {
      case CartActionType.SetItemUpdating:
        mutateItemQuantityChange({ cartItem: currentCartItem, newQuantity: currentProductQuantity });
        break;
      case CartActionType.SetItemRemoving:
        mutateItemRemove(currentCartItem);
        break;
    }
  }, [currentCartItem, currentProductQuantity, cartAction]);

  //Serve per avere la quantità nel carrello del prodotto per un messaggio della giacenza più accurato
  useEffect(() => {
    if (!currentProduct || !user) return;
    setCartCurrentQuantity(
      cartState.cart?.cart_items.find((item) => item.product.id === currentProduct.id)?.quantity || 0,
    );
  }, [user, currentProduct, currentQuantity]);

  //al cambio del token, pulisce il carrello
  useEffect(() => {
    if (token) {
      fetchCart();
    }
    dispatchStateCart({ type: CartActionType.EmptyCart });
  }, [token]);

  //MUTATIONS
  //fetch cart
  const { mutateAsync: fetchCart, isLoading: isFetchingCart } = useMutation(
    ['fetchCart', token],
    async () => {
      return myCart(token!, lang);
    },
    {
      onSuccess: (data) => {
        dispatchStateCart({ type: CartActionType.SetCart, cart: data.data });
      },
      onError: () => {
        resetCurrentProductAndQuantity();
        dispatchAlertChange({
          type: AlertType.Error,
          open: true,
          message: t('SHOPPING_CART:ADD_ERROR'),
        });
      },
    },
  );

  const { mutateAsync: mutateAddProductToCart, isLoading: isItemAdding } = useMutation(
    'addProductToCart',
    ({
      product,
      quantity,
      setAddingState,
    }: {
      product: IProduct;
      quantity: number;
      setAddingState?: (adding: boolean) => void;
    }) => addProductToCart(product, quantity, setAddingState),
    {
      onMutate: () => {
        dispatchStateCart({ type: CartActionType.SetItemAdding, itemId: currentProduct?.id, isItemAdding: true });
      },
      onSuccess: async () => {
        await fetchCart();
        dispatchCartOverlayChange({ open: true });
      },
      onError: () => {
        dispatchStateCart({
          type: CartActionType.SetItemAdding,
          itemId: currentProduct?.id,
          isItemAdding: false,
        });
        resetCurrentProductAndQuantity();
        dispatchAlertChange({
          type: AlertType.Error,
          open: true,
          message: t('SHOPPING_CART:ADD_ERROR'),
        });
      },
      onSettled: () => {
        dispatchStateCart({ type: CartActionType.SetItemAdding, itemId: currentProduct?.id, isItemAdding: false });
      },
    },
  );

  //find product stock
  const { mutateAsync: mutateProductStock, data: productStock } = useMutation('findProductStock', findProductStock, {
    onSuccess: (data) => {
      if (!currentProduct)
        return dispatchAlertChange({
          open: true,
          type: AlertType.Info,
          message: t('PRODUCTS:UNAVAILBLE_PRODUCT'),
        });
      const quantityInputProps = _quantityInputProps(currentProduct, user);
      const disabled = quantityInputProps.disabled;
      const { quantityDefaultWarehouse: greenQuantity } = data;
      const canUserOrder = getCanUserOrder({ user, product: currentProduct, quantity: currentQuantity });

      //se l'utente non può ordinare interrompo qui
      if (!canUserOrder || disabled)
        return dispatchAlertChange({
          open: true,
          type: AlertType.Info,
          message: t('PRODUCTS:UNAVAILBLE_PRODUCT'),
        });
      //la modale con le informazioni sullo stock va aperta soltanto quando la quantità richiesta supera
      //la quantità "verde"
      if (currentQuantity > greenQuantity) {
        return toggleStockExceededModal();
      }
      if (isProductInCart(cartState.cart, currentProduct) && displayAddToCartModal) {
        return toggleIsInCartModal();
      }
      mutateAddProductToCart({ product: currentProduct, quantity: currentQuantity, setAddingState: setAdding });
    },
    onError: () => {
      resetCurrentProductAndQuantity();
      dispatchAlertChange({
        type: AlertType.Error,
        open: true,
        message: t('SHOPPING_CART:ADD_ERROR'),
      });
    },
  });

  //flush cart
  const { mutateAsync: mutateFlushCart, isLoading: isFlushingCart } = useMutation('flushCart', onFlushCart, {
    onMutate: () => {
      dispatchStateCart({ type: CartActionType.SetFlushingCart, isFlushingCart: true });
    },
    onSuccess: async () => await fetchCart(),
    onError: () => {
      dispatchStateCart({ type: CartActionType.SetFlushingCart, isFlushingCart: false });
      resetCurrentProductAndQuantity();
      dispatchAlertChange({
        type: AlertType.Error,
        open: true,
        message: t('SHOPPING_CART:ADD_ERROR'),
      });
    },
    onSettled: () => {
      dispatchStateCart({ type: CartActionType.SetFlushingCart, isFlushingCart: false });
    },
  });

  //remove item
  const { mutateAsync: mutateItemRemove, isLoading: isRemovingItem } = useMutation('deleteCartItem', itemRemove, {
    onMutate: () => {
      dispatchStateCart({ type: CartActionType.SetItemRemoving, itemId: currentCartItem?.id, isItemRemoving: true });
    },
    onSuccess: async () => await fetchCart(),
    onError: () => {
      dispatchStateCart({
        type: CartActionType.SetItemRemoving,
        itemId: currentCartItem?.id,
        isItemRemoving: false,
      });
      resetCurrentCartItemAndQuantity();
      dispatchAlertChange({
        type: AlertType.Error,
        open: true,
        message: t('SHOPPING_CART:REMOVE_ERROR'),
      });
    },
    onSettled: () => {
      dispatchStateCart({ type: CartActionType.SetItemRemoving, itemId: currentCartItem?.id, isItemRemoving: false });
    },
  });

  //update item
  const { mutateAsync: mutateItemQuantityChange, isLoading: isUpdatingItem } = useMutation(
    'updateCartItem',
    itemQuantityChange,
    {
      onMutate: () => {
        dispatchStateCart({ type: CartActionType.SetItemUpdating, itemId: currentCartItem?.id, isItemUpdating: true });
      },
      onSuccess: async () => await fetchCart(),
      onError: () => {
        dispatchStateCart({ type: CartActionType.SetItemUpdating, itemId: currentCartItem?.id, isItemUpdating: false });
        resetCurrentCartItemAndQuantity();
        dispatchAlertChange({
          type: AlertType.Error,
          open: true,
          message: t('SHOPPING_CART:UPDATE_ERROR'),
        });
      },
      onSettled: () => {
        dispatchStateCart({ type: CartActionType.SetItemUpdating, itemId: currentCartItem?.id, isItemUpdating: false });
      },
    },
  );

  //update cart item
  const { mutate: updateCartItemMutation } = useMutation(
    'updateCartItemMutation',
    (params: {
      token: string;
      cartItem: ICartItem;
      product: IProduct;
      quantity: number;
      excerpt: boolean;
      selected: boolean;
    }) => {
      return updateCartItem(
        params.token,
        params.cartItem,
        params.product,
        params.quantity,
        params.excerpt,
        params.selected,
      );
    },
    {
      onMutate: () => {
        dispatchStateCart({ type: CartActionType.SetItemUpdating, itemId: currentProduct?.id, isItemUpdating: true });
      },
      onSuccess: async () => await fetchCart(),
      onError: () => {
        dispatchStateCart({ type: CartActionType.SetItemUpdating, itemId: currentProduct?.id, isItemUpdating: false });
        return dispatchAlertChange({
          open: true,
          message: t('SHOPPING_CART:UPDATE_ERROR'),
        });
      },
      onSettled: () => {
        dispatchStateCart({ type: CartActionType.SetItemUpdating, itemId: currentProduct?.id, isItemUpdating: false });
      },
    },
  );

  //select all for order, è la checkbox nel carrello per selezionare tutti i prodotti per l'ordine
  const { mutateAsync: mutateSelectAllForOrder, isLoading: isSelectingAllForOrder } = useMutation(
    'selectAllForOrder',
    ({ token, cartId, selectedForOrder }: { token: string; cartId: number; selectedForOrder: boolean }) =>
      selectAllForOrder(token, cartId, selectedForOrder),
    {
      onMutate: () => {
        dispatchStateCart({ type: CartActionType.SetItemUpdating, itemId: currentProduct?.id, isItemUpdating: true });
      },
      onSuccess: () => {
        fetchCart();
      },
      onError: () => {
        dispatchStateCart({ type: CartActionType.SetItemUpdating, itemId: currentProduct?.id, isItemUpdating: false });
        return dispatchAlertChange({
          open: true,
          message: t('SHOPPING_CART:UPDATE_ERROR'),
        });
      },
      onSettled: () => {
        dispatchStateCart({ type: CartActionType.SetItemUpdating, itemId: currentProduct?.id, isItemUpdating: false });
      },
    },
  );

  const { mutateAsync: mutateOnAddAll, isLoading: isAddingAll } = useMutation<
    void,
    Error,
    { products: IProduct[]; productQuantities: IProductQuantity[] }
  >('onAddAll', ({ products, productQuantities }) => onAddAll(products, productQuantities), {
    onMutate: () => {
      dispatchStateCart({ type: CartActionType.SetAddingBulk, isAddingBulk: true });
    },
    onSuccess: async () => await fetchCart(),
    onError: () => {
      dispatchStateCart({ type: CartActionType.SetAddingBulk, isAddingBulk: false });
      return dispatchAlertChange({
        open: true,
        message: t('SHOPPING_CART:UPDATE_ERROR'),
      });
    },
    onSettled: () => {
      dispatchStateCart({ type: CartActionType.SetAddingBulk, isAddingBulk: false });
    },
  });

  //loading globale ogni qualvolta che è in corso una mutation
  useEffect(() => {
    dispatchStateCart({
      type: CartActionType.SetLoading,
      isLoading:
        isFetchingCart ||
        isFlushingCart ||
        isRemovingItem ||
        isUpdatingItem ||
        searching ||
        isSelectingAllForOrder ||
        isItemAdding ||
        isAddingAll,
    });
  }, [
    isFetchingCart,
    isFlushingCart,
    isRemovingItem,
    isUpdatingItem,
    searching,
    isSelectingAllForOrder,
    isItemAdding,
    isAddingAll,
  ]);

  const resetCurrentProductAndQuantity = () => {
    setCurrentProduct(null);
    setCurrentQuantity(0);
  };
  const resetCurrentCartItemAndQuantity = () => {
    setCurrentCartItem(null);
    setCurrentProductQuantity(0);
  };
  return {
    isInCartModalOpen,
    adding,
    toggleIsInCartModal,
    onAddToCart,
    handleConfirm,
    onAddAll: mutateOnAddAll,
    currentProduct,
    currentQuantity,
    onAddToCartStockExceeded,
    isStockExceededModalOpen,
    toggleStockExceededModal,
    productStock,
    cartCurrentQuantity,
    cartState,
    fetchCart,
    dispatchStateCart,
    onFlushCart: mutateFlushCart,
    onItemRemove,
    onItemQuantityChange,
    dispatchOverlayChange: dispatchCartOverlayChange,
    isOverlayOpen,
    onSearchAddToCart,
    onExcerptChange,
    onSelectedForOderChange,
    toggleFlushCartModal,
    isFlushCartModalOpen,
    flushCartWithConfirm,
    selectAllForOrder: mutateSelectAllForOrder,
    isOverlayEnabled,
    setIsOverlayEnabled,
    freeCartItem,
  };
};

export default useCartUtilities;
