import React, { useMemo, Fragment } from 'react';
import {
  ShoppingBagIcon,
  ShoppingCartIcon,
  ChartBarIcon,
  EllipsisVerticalIcon,
  NewspaperIcon,
  ChatBubbleLeftIcon,
  FolderIcon,
  DocumentIcon,
  CurrencyEuroIcon,
  GiftTopIcon,
} from '@heroicons/react/24/solid';
import { useTranslation } from 'react-i18next';
import { ROUTES } from '../../../routes';
import { Link } from 'react-router-dom';
import { useAuth } from '../../../providers/AuthProvider';
import { isOrdersViewEnabled } from '../../../lib/utils/userPermissions';
import { Menu, Transition } from '@headlessui/react';
import { classNames } from '../../../lib/utils';

const AllFunctionsButton: React.FC = () => {
  const { t } = useTranslation('MENU');
  const { t: tNav } = useTranslation('NAVIGATION');
  const [{ user }] = useAuth();
  const areOrdersEnabled = useMemo(() => isOrdersViewEnabled(user!), [user]);

  const allFunctionsItems = [
    {
      icon: CurrencyEuroIcon,
      label: tNav('PROMOTIONS'),
      link: ROUTES.PROMOTIONS.LIST,
    },
    {
      icon: ShoppingCartIcon,
      label: tNav('ORDERS'),
      link: ROUTES.ORDERS.LIST,
      disabled: !areOrdersEnabled,
    },
    {
      icon: GiftTopIcon,
      label: tNav('DELIVERY_NOTES'),
      link: ROUTES.PROFILE.DOCUMENTS,
    },
    {
      icon: DocumentIcon,
      label: tNav('DOCUMENTS'),
      link: '/search-results',
      disabled: true,
    },
    {
      icon: NewspaperIcon,
      label: tNav('NEWS'),
      link: ROUTES.NEWS.LIST,
    },
    {
      icon: ChatBubbleLeftIcon,
      label: tNav('COMMUNICATIONS'),
      link: '/search-results',
      disabled: true,
    },
    {
      icon: ShoppingBagIcon,
      label: tNav('RETURNS'),
      link: ROUTES.PROFILE.RETURNS,
    },
    {
      icon: FolderIcon,
      label: tNav('CATALOGUES'),
      link: '/search-results',
      disabled: true,
    },
    {
      icon: ChartBarIcon,
      label: tNav('STATISTICS'),
      link: ROUTES.PROFILE.COMMERCIAL_STATS,
    },
  ];

  return (
    <Menu as="div" className="relative inline-block text-left">
      <Menu.Button className="group block flex-shrink-0">
        <button className="flex w-full items-center justify-center rounded-full border border-transparent bg-blue-500 py-2 px-3 text-xs font-normal text-white shadow-sm hover:bg-blue-600 focus:outline-none lg:w-auto lg:rounded-md">
          <span className="hidden sm:inline-block">{t('ALL_FUNCTIONS')}</span>
          <EllipsisVerticalIcon className="h-4 w-4" aria-hidden="true" />
        </button>
      </Menu.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute max-lg:left-0 lg:right-0 z-10 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-1">
            {allFunctionsItems.map(({ icon: Icon, label, link, disabled }) => (
              <Menu.Item key={label} disabled={disabled}>
                {({ active }) => (
                  <Link
                    to={link}
                    className={classNames(
                      active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                      disabled ? 'pointer-events-none opacity-50' : '',
                      'group flex items-center px-4 py-2 text-sm',
                    )}
                  >
                    <Icon className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
                    {label}
                  </Link>
                )}
              </Menu.Item>
            ))}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

export default AllFunctionsButton;
