import { useReducer } from 'react';
import { CartAction, CartActionType, CartState } from '../../lib/interfaces/cart';

export const INITIAL_CART_STATE: CartState = {
  isLoading: false,
  cart: null,
  totalQuantity: 0,
  isFlushingCart: false,
  setCart: () => {},
  itemLoading: {},
  itemAdding: {},
  itemRemoving: {},
  isAddingBulk: false,
  itemUpdating: {},
};

const reducer = (state: CartState, action: CartAction): CartState => {
  const {
    type,
    cart = null,
    isLoading = false,
    itemId = null,
    isItemLoading = false,
    isItemAdding = false,
    isFlushingCart = false,
    isAddingBulk = false,
    isItemUpdating = false,
    isItemRemoving = false,
  } = action;
  switch (type) {
    case CartActionType.EmptyCart:
      return {
        ...INITIAL_CART_STATE,
      };
    case CartActionType.SetCart:
      return {
        ...state,
        cart,
        totalQuantity: cart?.cart_items.filter(({ free }) => !free).length || 0,
      };
    case CartActionType.SetLoading:
      return {
        ...state,
        isLoading,
      };
    case CartActionType.SetItemLoading:
      if (itemId === undefined || itemId === null) {
        return state;
      }
      return {
        ...state,
        itemLoading: { ...state.itemLoading, [itemId]: isItemLoading },
      };
    case CartActionType.SetItemAdding:
      if (itemId === undefined || itemId === null) {
        return state;
      }
      return {
        ...state,
        itemAdding: { ...state.itemAdding, [itemId]: isItemAdding },
      };
    case CartActionType.SetFlushingCart:
      return {
        ...state,
        isFlushingCart: isFlushingCart,
      };
    case CartActionType.SetAddingBulk:
      return {
        ...state,
        isAddingBulk: isAddingBulk,
      };
    case CartActionType.SetItemUpdating:
      if (itemId === undefined || itemId === null) {
        return state;
      }
      return {
        ...state,
        itemUpdating: { ...state.itemUpdating, [itemId]: isItemUpdating },
      };
    case CartActionType.SetItemRemoving:
      if (itemId === undefined || itemId === null) {
        return state;
      }
      return {
        ...state,
        itemRemoving: { ...state.itemRemoving, [itemId]: isItemRemoving },
      };
    default:
      return { ...state };
  }
};

const useCartState = () => {
  const [cartState, dispatchStateCart] = useReducer(reducer, INITIAL_CART_STATE);
  return { cartState, dispatchStateCart };
};

export default useCartState;
