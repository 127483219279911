import { useState } from 'react';

export const useCartModals = () => {
  const [isInCartModalOpen, setIsInCartModalOpen] = useState(false);
  const [isStockExceededModalOpen, setIsStockExceededModalOpen] = useState(false);
  const [isFlushCartModalOpen, setIsFlushCartModalOpen] = useState(false);

  return {
    isInCartModalOpen,
    toggleIsInCartModal: () => setIsInCartModalOpen((v) => !v),
    isStockExceededModalOpen,
    toggleStockExceededModal: () => setIsStockExceededModalOpen((v) => !v),
    isFlushCartModalOpen,
    toggleFlushCartModal: () => setIsFlushCartModalOpen((v) => !v),
  };
};
