import { useState } from 'react';
import { getCrossReferences, IProduct } from '../../../lib/fetch/products';
import PromotionPresentational from './PromotionPresentational';
import { useAuth } from '../../../providers/AuthProvider';
import { useAlert } from '../../../providers/AlertProvider';
import i18n from '../../../i18n';
import { StringParam, useQueryParam } from 'use-query-params';
import { callGetPromoProducts } from '../../../lib/fetch/promotions';
import { useParams } from 'react-router-dom';
import { useCart } from '../../../providers/CartProvider';
import { useQuery } from 'react-query';
const { REACT_APP_BIG_PAGE_SIZE } = process.env;

const Promotion = () => {
  const [{ token }] = useAuth();
  const [, dispatchAlertChange] = useAlert();
  const lang = i18n.languages[0];
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(+REACT_APP_BIG_PAGE_SIZE! || 50);
  const [sort] = useQueryParam('sort', StringParam);

  const { id: promotionId } = useParams<{ id: string }>();
  const {
    cartState: { isLoading: isCartLoading },
  } = useCart();

  const onFetchCrossReferences = async (productId: number): Promise<IProduct[]> => {
    const { error, data: responseData } = await getCrossReferences(token!, productId, lang);
    if (error || !responseData.data) {
      return [];
    }
    return responseData.data;
  };

  const { data, isFetching: isPromoInfosLoading } = useQuery(
    ['getProductsForCurrentPromo', promotionId, page, pageSize, sort],
    () =>
      callGetPromoProducts(token!, {
        id: String(promotionId),
        page,
        pageSize,
        sort: sort!,
      }),
    {
      onSuccess: () => {
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
      },
      onError: () => {
        dispatchAlertChange({ open: true });
      },
      enabled: !!promotionId,
      refetchOnWindowFocus: false,
    },
  );

  return (
    <>
      <PromotionPresentational
        onPageChange={setPage}
        onPageSizeChange={setPageSize}
        onFetchCrossReferences={onFetchCrossReferences}
        products={data?.data?.products}
        page={page}
        pageCount={data?.meta?.pagination?.pageCount}
        pageSize={pageSize}
        total={data?.meta?.pagination?.total}
        adding={isCartLoading}
        loading={isPromoInfosLoading}
        promotion={data?.data?.promotion}
      />
    </>
  );
};

export default Promotion;
