import { useState, useEffect, useRef } from 'react';
import { ExclamationCircleIcon } from '@heroicons/react/24/solid';
import { useTranslation } from 'react-i18next';
import { CSSTransition } from 'react-transition-group';
import './ImportantMessages.css';

const mockData = [
  {
    id: 1,
    text: 'Gentile cliente, a partire da oggi il catalogo a3 verrà dismesso. La ricerca dei particolari TreVi resterà possibile all\'interno del nostro e-commerce, con la ricerca per targa/VIN e consultando la sezione "link utili".',
  },
  {
    id: 2,
    text: 'Attenzione: Tutti gli ordini inviati da questo canale non verranno processati automaticamente. Grazie, lo staff B2B.',
  },
  {
    id: 3,
    text: "Si informa la spettabile clientela che è attiva la Ricerca OEM by Kromeda selezionando l'opzione CODICE OEM dalla tendina.",
  },
  {
    id: 4,
    text: 'Si informa la spettabile clientela che: immagini, schede tecniche, cross e codici OEM presenti nella scheda prodotto sono forniti da TECDOC.',
  },
];

const ImportantMessages = () => {
  const { t } = useTranslation('ALERT');
  const [currentMessageIndex, setCurrentMessageIndex] = useState(0);
  const [isExiting, setIsExiting] = useState(false);
  const intervalRef = useRef<NodeJS.Timer | null>(null);
  const pRef = useRef(null);

  const startInterval = () => {
    if (mockData.length > 1) {
      intervalRef.current = setInterval(() => {
        setIsExiting(true);
      }, 5000);
    }
  };

  useEffect(() => {
    startInterval();
    return () => {
      if (intervalRef.current !== null) {
        clearInterval(intervalRef.current);
      }
    };
  }, []);

  const handleExited = () => {
    setCurrentMessageIndex((prevIndex) => (prevIndex + 1) % mockData.length);
    setIsExiting(false);
  };

  const handleDotClick = (index: number) => {
    if (index !== currentMessageIndex) {
      if (intervalRef.current !== null) {
        clearInterval(intervalRef.current);
      }
      setIsExiting(true);
      setTimeout(() => {
        setCurrentMessageIndex(index);
        setIsExiting(false);
        startInterval(); // Riavvia il timer dopo il cambio manuale
      }, 500); // Durata dell'effetto di dissolvenza
    }
  };

  return (
    <div className="mb-4 rounded-md border border-yellow-600 px-2 py-4">
      <div className="flex justify-between">
        <h3 className="text-base font-medium text-yellow-600 font-bold">{t('IMPORTANT_MESSAGE_TITLE')}</h3>
        <ExclamationCircleIcon className="h-6 w-6 text-yellow-600" aria-hidden="true" />
      </div>

      <div className="mt-2 text-sm text-gray-700 fixed-height" title={mockData[currentMessageIndex].text}>
        <CSSTransition in={!isExiting} timeout={500} classNames="fade" onExited={handleExited} unmountOnExit>
          <p ref={pRef}>{mockData[currentMessageIndex].text}</p>
        </CSSTransition>
      </div>

      {mockData.length > 1 && (
        <div className="flex justify-center mt-2">
          {mockData.map((_, index) => (
            <span
              key={index}
              onClick={() => handleDotClick(index)}
              className={`h-2 w-2 mx-1 rounded-full cursor-pointer ${
                index === currentMessageIndex ? 'bg-yellow-600' : 'bg-gray-300'
              }`}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default ImportantMessages;
