import axios from 'axios';

interface Params {
  productId: string;
  promoApplicationId: string;
  token: string;
}

const instance = axios.create({
  baseURL: `${process.env.REACT_APP_BACKEND_URL}/promo-applications`,
});

const selectGiftProduct = async (params: Params) => {
  const { productId, promoApplicationId, token } = params;
  await instance.post(
    `/${promoApplicationId}/choose-gift-product`,
    { productId },
    { headers: { Authorization: `Bearer ${token}` } },
  );
};

export default selectGiftProduct;
