import { useState } from 'react';
import { ICustomerForAgent } from '../../lib/interfaces/ICustomerForAgent';

export const useAgentCustomerModals = () => {
  const [modalState, setModalState] = useState<{
    type: 'DETAILS' | 'PROMOTIONS' | undefined;
    data: ICustomerForAgent | undefined;
  }>({ type: undefined, data: undefined });

  const toggleModal = (type: 'DETAILS' | 'PROMOTIONS' | undefined, data: ICustomerForAgent | undefined = undefined) => {
    setModalState((prev) => ({
      type: prev.type === type ? undefined : type,
      data,
    }));
  };

  return {
    modalState,
    toggleModal,
  };
};
