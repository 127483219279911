import { useLocation } from 'react-router-dom';
import ShoppingCartPresentational from '../ShoppingCart/ShoppingCartPresentational';
import { ICart } from '../../lib/fetch/carts';

const AgentCart = () => {
  const location = useLocation();
  const { state } = location;
  // @ts-ignore
  const cart: ICart = (state ?? {}).cart;
  const { shipping_address } = cart || {};
  const shippingAddressId = (shipping_address && shipping_address.id) || undefined;

  return (
    <ShoppingCartPresentational
      cart={cart}
      deliveryAddressId={shippingAddressId || undefined}
      readOnly={true}
      //non deve fare niente da qui
      onProductDeselection={() => {}}
      onProductSelection={() => {}}
    />
  );
};

export default AgentCart;
