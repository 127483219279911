import { useMemo } from 'react';
import { IGetProductsFinalPricesResponse } from '../../lib/fetch/products/getProductsFinalPrices';

interface UseProductPricesProps {
  promoPrice?: IGetProductsFinalPricesResponse;
  quantity: number;
}

export const useProductPrices = ({ promoPrice, quantity }: UseProductPricesProps) => {
  const basePrice = useMemo(() => {
    if (!promoPrice) return 0;
    return promoPrice.price_net * quantity;
  }, [promoPrice, quantity]);

  const finalPrice = useMemo(() => {
    if (!promoPrice) return 0;
    return promoPrice.promo_price * quantity;
  }, [promoPrice, quantity]);

  const isPromo = useMemo(() => {
    return basePrice > 0 && basePrice !== finalPrice;
  }, [basePrice, finalPrice]);

  const discountPercentage = useMemo(() => {
    return promoPrice?.bestPromotionTier?.effect?.discountPercentage;
  }, [promoPrice]);
  return { basePrice, finalPrice, isPromo, discountPercentage };
};
