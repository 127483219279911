import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ICartItem } from '../../../lib/fetch/cartItems';
import { useSettings } from '../../../providers/SettingsProvider';
import { CheckAll } from '../../Checkbox';
import ShoppingCartRow from './ShoppingCardRow';
import { useCart } from '../../../providers/CartProvider';
import { useAuth } from '../../../providers/AuthProvider';

interface IShoppingCartTable {
  cartItems: ICartItem[];
  readOnly?: boolean;
  loading?: boolean;
}

const ShoppingCartTable: FC<IShoppingCartTable> = (props) => {
  const [{ token }] = useAuth();
  const { cartItems = [], readOnly = false, loading = false } = props;
  const { t } = useTranslation('PRODUCTS');
  const { t: tProducts } = useTranslation('PRODUCTS');
  const [{ tecdocManufacturers }] = useSettings();
  const {
    cartState: { cart },
    onSelectedForOderChange,
    selectAllForOrder,
  } = useCart();
  const [selectedItems, setSelectedItems] = useState<number[]>([]);
  const isAllChecked = useMemo(
    () => cartItems.length > 0 && selectedItems.length === cartItems.length,
    [cartItems, selectedItems],
  );
  const toggleAll = async () => {
    if (isAllChecked) {
      try {
        await selectAllForOrder({ token: token!, cartId: cart!.id, selectedForOrder: false });
        setSelectedItems([]);
      } catch (error) {}
    } else {
      const allItemIds = cartItems.map((cartItem) => cartItem.id);
      try {
        await selectAllForOrder({ token: token!, cartId: cart!.id, selectedForOrder: true });
        setSelectedItems(allItemIds);
      } catch (error) {}
    }
  };

  const handleItemChange = (item: any, isChecked: boolean) => {
    onSelectedForOderChange(item, isChecked);
    /*     setSelectedItems((prevSelected) =>
      isChecked ? [...prevSelected, item.id] : prevSelected.filter((id) => id !== item.id),
    ); */
  };
  //Preseleziono gli item già selezionati sul BE
  useEffect(() => {
    const initiallySelected = cartItems.filter((item) => item.selectedForOrder).map((item) => item.id);
    setSelectedItems(initiallySelected);
  }, [cartItems]);

  return (
    <div className="flex flex-col">
      <div className="overflow-x-auto">
        <div className="inline-block min-w-full py-2 align-middle ">
          <div className="border-gray-200  border rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th scope="col" className="relative py-3 pl-2">
                    <CheckAll onChange={toggleAll} isAllChecked={isAllChecked} />
                  </th>
                  <th scope="col" className="relative py-3 pl-2">
                    <span className="sr-only">{t('DETAILS')}</span>
                  </th>
                  <th
                    scope="col"
                    className="px-4 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                  >
                    {t('PRODUCT')}
                  </th>
                  <th
                    scope="col"
                    className="px-4 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                  >
                    {t('BRAND')}
                  </th>
                  <th
                    scope="col"
                    className="px-4 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                  >
                    {tProducts('STOCKS')}
                  </th>
                  <th
                    scope="col"
                    className="px-4 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                  >
                    {t('PRICE')}
                  </th>
                  <th
                    scope="col"
                    className="px-4 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                  >
                    {t('PRICE_NET')}
                  </th>
                  <th
                    scope="col"
                    className="px-4 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                  >
                    {t('FINAL_PRICE')}
                  </th>
                  <th
                    scope="col"
                    className="px-4 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                  >
                    {t('EXCERPT')}
                  </th>
                  <th
                    scope="col"
                    className="sticky right-0 z-5 bg-gray-50 px-4 py-3 text-right text-xs font-medium uppercase tracking-wider text-gray-500"
                  >
                    {t('ACTION')}
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white">
                {cartItems.map((cartItem, index) => {
                  return (
                    <ShoppingCartRow
                      key={index}
                      cartItem={cartItem}
                      readOnly={readOnly}
                      selectedItems={selectedItems}
                      handleItemChange={handleItemChange}
                      tecdocManufacturers={tecdocManufacturers}
                      isLoading={loading}
                    />
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShoppingCartTable;
