import { DevicePhoneMobileIcon, EyeIcon, MapPinIcon, PhoneIcon } from '@heroicons/react/24/solid';
import { ArrowRightEndOnRectangleIcon } from '@heroicons/react/24/outline';
import { setCustomer } from '../../../lib/fetch/agent';
import { AuthActionType, useAuth } from '../../../providers/AuthProvider';
import { useNavigate } from 'react-router-dom';
import { useEffect, useMemo, useState } from 'react';
import Filters from './Filters';
import { CustomerDetailsModal } from '../../../components/CustomerDetails';
import { AgentPromotionsDetailsModal } from '../../../components/AgentPromotions';
import { Pagination } from '../../../components/ProductsTable';
import { useTranslation } from 'react-i18next';
import FullScreenLoading from '../../../components/FullScreenLoading';
import { currency } from '../../../lib/utils';
import { useCart } from '../../../providers/CartProvider';
import useAgentCustomers from '../../../hooks/customer/useAgentCustomers';
import { useAgentCustomerModals } from '../../../hooks/customer/useAgentCustomerModals';
import { FixedAlert } from '../../../components/Alert';
import { AlertType } from '../../../providers/AlertProvider';

const PAGE_SIZE = 20;

const CustomersList = () => {
  const { t } = useTranslation('CUSTOMER_LIST');
  const { t: tCommon } = useTranslation('COMMON');

  const [filters, setFilters] = useState({
    customer_id: '',
    address: '',
    name: '',
    vat_num: '',
    active: 'true',
  });

  const [page, setPage] = useState(1);
  const [debounceTimeout, setDebounceTimeout] = useState<NodeJS.Timeout | null>(null);

  const { modalState, toggleModal } = useAgentCustomerModals();
  const { fetchCart } = useCart();
  const navigate = useNavigate();
  const [{ token }, dispatchAuthChange] = useAuth();

  const { data, isLoading, isError } = useAgentCustomers({ token: token!, filters, page, pageSize: PAGE_SIZE });

  const foundNoCustomers = useMemo(() => data?.customersWithPromotions?.length === 0, [data]);

  useEffect(() => {
    if (debounceTimeout) {
      clearTimeout(debounceTimeout);
    }
    const timeout = setTimeout(() => {
      setPage(1);
    }, 1000);
    setDebounceTimeout(timeout);
    return () => clearTimeout(timeout);
  }, [filters]);

  const handleFilterChange = (filterName: string, value: string) => {
    setFilters((prev) => ({
      ...prev,
      [filterName]: value,
    }));
  };

  const impersonateCustomer = async (customerId: number) => {
    const { data: updatedUser } = await setCustomer(token!, customerId);
    dispatchAuthChange({
      type: AuthActionType.SetUser,
      user: updatedUser,
    });
    await fetchCart();
    navigate('/search-results');
  };

  return (
    <>
      <FullScreenLoading loading={isLoading} title={tCommon('LOADING')} subtitle={tCommon('LOADING_SUBTITLE')} />
      <div className="overflow-x-auto m-8">
        <Filters filters={filters} onFilterChange={handleFilterChange} />
        <CustomerDetailsModal
          isOpen={modalState.type === 'DETAILS'}
          onRequestClose={() => toggleModal(undefined)}
          customerDetailsProps={modalState.data ?? undefined}
        />
        <AgentPromotionsDetailsModal
          isOpen={modalState.type === 'PROMOTIONS'}
          onRequestClose={() => toggleModal(undefined)}
          promotionsDetailsProps={modalState.data ?? undefined}
        />
        {isError ? (
          <div className="flex justify-center items-start h-screen mt-16">
            <FixedAlert title={t('ERROR_SEARCHING_CUSTOMERS')} type={AlertType.Error} />
          </div>
        ) : foundNoCustomers ? (
          <div className="flex justify-center items-start h-screen mt-16">
            <FixedAlert title={t('NO_CUSTOMERS_FOUND')} type={AlertType.Info} />
          </div>
        ) : (
          <table className="min-w-full bg-white rounded-t-lg overflow-hidden">
            <thead className="bg-gray-50 text-gray-500">
              <tr className="rounded">
                <th className="px-2 py-2">{t('INFOS')}</th>
                <th className="px-2 py-2">{t('CONTACTS')}</th>
                <th className="px-4 py-2">{t('ADDRESS')}</th>
                <th className="px-4 py-2">{t('CATEGORY')}</th>
                <th className="px-4 py-2">{t('STATE')}</th>
                <th className="px-4 py-2">{t('NUMBER_ORDERS')}</th>
                <th className="px-4 py-2">{t('AMOUNT_ORDERS')}</th>
                <th className="px-4 py-2">{t('TRUST')}</th>
                <th className="px-4 py-2">{t('CLIENT_PROMOTIONS')}</th>
                <th className="px-4 py-2">{t('AGENT_PROMOTIONS')}</th>
                <th className="px-4 py-2">{t('ACTIONS')}</th>
              </tr>
            </thead>
            <tbody>
              {data?.customersWithPromotions?.map((item, index) => (
                <tr key={index} className="border">
                  <td className="px-4 py-2">
                    <p className="text-sm text-gray-400">
                      {item?.customer_id ? `${t('CODE')}: ${item.customer_id}` : ''}
                    </p>
                    <p className="text-sm font-bold">{item?.name}</p>
                    <p className="text-sm text-gray-400">{item?.vat_num ? `${t('P_IVA')}: ${item.vat_num}` : ''}</p>
                    <p className="text-sm text-gray-400">
                      {item?.fiscal_code ? `${t('FISCAL_CODE')}: ${item.fiscal_code}` : ''}
                    </p>
                  </td>
                  <td className="px-4 py-2 text-sm text-gray-400">
                    <div className="flex flex-col align-center my-2">
                      <div className="flex flex-row">
                        <PhoneIcon className="h-4 w-4 mr-1 mt-1 flex-shrink-0" />
                        <p className="text-sm text-gray-400">{item?.phone || t('NO_DATA')}</p>
                      </div>
                      <div className="flex flex-row">
                        <DevicePhoneMobileIcon className="h-4 w-4 mr-1 mt-1 flex-shrink-0" />
                        <p className="text-sm text-gray-400">{item?.telex || t('NO_DATA')}</p>
                      </div>
                    </div>
                  </td>
                  <td className="px-4 py-2 text-sm text-gray-400">
                    <div className="flex align-center my-2">
                      <MapPinIcon className="h-4 w-4 mr-1 mt-1 flex-shrink-0" />
                      {`${item?.address ? `${item.address},` : ''} ${item?.zipcode || ''} ${item?.city || ''} ${item?.province ? `(${item.province})` : ''} ${item?.country ? ` - ${item.country}` : ''}`}
                    </div>
                  </td>
                  <td className="px-4 py-2 text-center">
                    <span className="bg-gray-200 text-gray-700 py-1 px-3 rounded-full text-xs mr-2 mb-2 whitespace-nowrap inline-block">
                      {item?.customer_class_id?.name}
                    </span>
                  </td>
                  <td className="px-4 py-2 whitespace-nowrap">
                    {item?.active ? (
                      <>
                        <span className="text-green-500 text-2xl mr-2">●</span>
                        <span className="text-green-500">{t('ACTIVE')}</span>
                      </>
                    ) : (
                      <>
                        <span className="text-red-500 text-2xl mr-2">●</span>
                        <span className="text-red-500">{t('INACTIVE')}</span>
                      </>
                    )}
                  </td>
                  <td className="px-4 py-2 text-center">
                    <span className="bg-gray-100 text-red-500 py-1 px-3 w-7 h-7 rounded-full shadow-md text-xs inline-flex items-center justify-center font-bold">
                      {item?.orders?.totalOrders || 0}
                    </span>
                  </td>
                  <td className="px-4 py-2 text-center">
                    <span className="text-gray-400 py-1 px-3 w-7 h-7 text-xs inline-flex items-center justify-center font-bold">
                      {currency(item?.orders?.totalSpent || 0)}
                    </span>
                  </td>
                  <td className="px-4 py-2 text-center">
                    <span className=" text-gray-400 py-1 px-3 w-7 h-7 text-xs inline-flex items-center justify-center font-bold">
                      {currency(item?.fido || 0)}
                    </span>
                  </td>
                  <td className="px-4 py-2 text-center">
                    <span
                      className={`bg-gray-100 text-red-500 py-1 px-3 w-7 h-7 rounded-full shadow-md text-xs inline-flex items-center justify-center font-bold ${
                        (item?.promotions?.customer_promotions?.length ?? 0) === 0
                          ? 'cursor-not-allowed opacity-50'
                          : 'cursor-pointer'
                      }`}
                      onClick={() =>
                        (item?.promotions?.customer_promotions?.length ?? 0) > 0 && toggleModal('PROMOTIONS', item)
                      }
                    >
                      {item?.promotions?.customer_promotions?.length ?? 0}
                    </span>
                  </td>
                  <td className="px-4 py-2 text-center">
                    <span
                      className={`bg-gray-100 text-red-500 py-1 px-3 w-7 h-7 rounded-full shadow-md text-xs inline-flex items-center justify-center font-bold ${
                        (item?.promotions?.agent_promotions?.length ?? 0) === 0
                          ? 'cursor-not-allowed opacity-50'
                          : 'cursor-pointer'
                      }`}
                      onClick={() =>
                        (item?.promotions?.agent_promotions?.length ?? 0) > 0 && toggleModal('PROMOTIONS', item)
                      }
                    >
                      {item?.promotions?.agent_promotions?.length ?? 0}
                    </span>
                  </td>
                  {/*<td className="px-4 py-2">
                {item.clusterList.map((cluster, i) => (
                  <div key={i} className="text-gray-400">
                    {cluster}
                  </div>
                ))}
              </td>*/}
                  <td className="px-4 py-2 flex">
                    <button
                      className="bg-gray-200 text-black rounded-md p-2 mr-2"
                      onClick={() => toggleModal('DETAILS', item)}
                    >
                      <EyeIcon className="h-5 w-5" />
                    </button>
                    <button
                      className="bg-red-500 text-white rounded-md p-2"
                      onClick={() => impersonateCustomer(item?.id)}
                    >
                      <ArrowRightEndOnRectangleIcon className="h-5 w-5" />
                    </button>
                  </td>
                </tr>
              ))}
              <Pagination
                currentItems={data?.customersWithPromotions || []}
                onPageChange={setPage}
                onPageSizeChange={() => {}}
                page={page}
                pageCount={data?.pagination?.pageCount || 1}
                pageSize={data?.pagination?.pageSize || PAGE_SIZE}
                total={data?.pagination?.total || 0}
              />
            </tbody>
          </table>
        )}
      </div>
    </>
  );
};

export default CustomersList;
