import { useAuth } from '../../providers/AuthProvider';
import ShoppingCartPresentational from './ShoppingCartPresentational';
import { useCart } from '../../providers/CartProvider';
import { useMutation } from 'react-query';
import promoApplicationsAPIs from '../../lib/fetch/promoApplications';
import { AlertType, useAlert } from '../../providers/AlertProvider';
import { useTranslation } from 'react-i18next';

const ShoppingCart = () => {
  const [{ user, token }] = useAuth();
  let { addresses } = user!;
  addresses = addresses || [];
  const {
    cartState: { cart },
    fetchCart,
  } = useCart();
  const [, dispatchAlertChange] = useAlert();
  const { t } = useTranslation();

  const { shipping_address } = cart || {};
  let shippingAddressId = shipping_address && shipping_address.id;
  const isShippingAddressIdValid = addresses.some(({ id }) => id === shippingAddressId);
  const deliveryAddressId = isShippingAddressIdValid ? shippingAddressId : undefined;

  const { mutate: selectGiftProductMutation } = useMutation('select-gift', promoApplicationsAPIs.selectGiftProduct, {
    onSuccess() {
      fetchCart();
      dispatchAlertChange({ type: AlertType.Success, open: true, message: t('COMMON:OPERATION_DONE') });
    },
    onError: () => {
      dispatchAlertChange({ type: AlertType.Error, open: true, message: t('COMMON:GENERIC_ERROR') });
    },
  });

  const { mutate: removeGiftProductMutation } = useMutation(
    'removeGift-gift',
    promoApplicationsAPIs.removeGiftProduct,
    {
      onSuccess() {
        fetchCart();
        dispatchAlertChange({ type: AlertType.Success, open: true, message: t('COMMON:OPERATION_DONE') });
      },
      onError: () => {
        dispatchAlertChange({ type: AlertType.Error, open: true, message: t('COMMON:GENERIC_ERROR') });
      },
    },
  );

  return (
    <>
      {cart && (
        <ShoppingCartPresentational
          onProductDeselection={(data) => {
            removeGiftProductMutation({ ...data, token: String(token) });
          }}
          onProductSelection={(data) => {
            selectGiftProductMutation({ ...data, token: String(token) });
          }}
          cart={cart}
          deliveryAddressId={deliveryAddressId || undefined}
          readOnly={false}
        />
      )}
    </>
  );
};

export default ShoppingCart;
