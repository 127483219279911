import { createContext } from 'react';
import { ICartContext, SelectAllForOrderParams } from '../../lib/interfaces/cart';
import { INITIAL_CART_STATE } from '../../hooks/cart/useCartState';
import { ICartItemUpdateResult } from '../../lib/fetch/cartItems';

const INITIAL_CART_CONTEXT: ICartContext = {
  cartState: INITIAL_CART_STATE,
  dispatchState: () => {},
  addToCart: async () => {},
  fetchCart: async () => {},
  onAddAll: async () => {},
  onFlushCart: async () => {},
  onItemRemove: async () => {},
  onItemQuantityChange: async () => {},
  dispatchOverlayChange: () => {},
  isOverlayOpen: false,
  onSearchAddToCart: async () => {},
  onExcerptChange: async () => {},
  onSelectedForOderChange: async () => {},
  flushCartWithConfirm: async () => {},
  selectAllForOrder: async (params: SelectAllForOrderParams) => ({
    data: { data: {} as ICartItemUpdateResult },
  }),
  isOverlayEnabled: true,
  setIsOverlayEnabled: () => {},
  adding: false,
};

export const CartContext = createContext<ICartContext>(INITIAL_CART_CONTEXT);
